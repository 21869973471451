import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import styled from 'styled-components'

function Loading({ isLoading }) {
  return (
    isLoading && (
      <Container isLoading={isLoading}>
        <StyledIndicator>
          <StyledBeatLoader color={'white'} loading={isLoading} size={40} />
        </StyledIndicator>
      </Container>
    )
  )
}

const StyledBeatLoader = styled(BeatLoader)`
  display: block;
  margin: 0 auto;
  border-color: rgba(255, 255, 255, 0.7);
  border-width: 10px;
`

const StyledIndicator = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
`

const Container = styled.div`
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow: auto;
  display: block;
  position: fixed !important;
  background-color: rgba(0, 0, 0, 0.3);
`

export default Loading
