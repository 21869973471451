import styled from 'styled-components'
import AboutCheongdam from './AboutCheongdam/AboutCheongdam'
import ContactUs from './ContactUs/ContactUs'
import NoticeAndNews from './NoticeAndNews/NoticeAndNews'
import Works from './Works/Works'

function Body({ style }) {
  return (
    <Container style={style}>
      <AboutCheongdam />
      <Works />
      <ContactUs />
      <NoticeAndNews />
    </Container>
  )
}

const Container = styled.div``

export default Body
