import styled from 'styled-components'
import React from 'react'
import axios from 'axios'
import { Scrollbar } from 'smooth-scrollbar-react'
import { useNavigate, useParams } from 'react-router-dom'

import Navbar from '../components/Navbar/Navbar'
import FooterBar from '../components/FooterBar/FooterBar'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import StyledLink from '../components/StyledLink'
import setMetaTags from '../utils/setMetaTags'

import { POST_TYPE_KOR_DICT } from '../utils/dictionarys'
import api from '../utils/api'

const POST_INIT_STATE = {
  title: '',
  created_date: '',
  type: '',
}
function BoardDetail() {
  const [enableScroll, setScroll] = React.useState(true)
  const [post, setPost] = React.useState(POST_INIT_STATE)
  const [prevPost, setPrevPost] = React.useState({})
  const [nextPost, setNextPost] = React.useState({})
  const { id } = useParams()

  const navigate = useNavigate()

  const fetchPost = React.useCallback(async () => {
    try {
      const res = await api.get(`/post/${id}`)

      setPost(res.data.current_post)
      setPrevPost(res.data.prev_post)
      setNextPost(res.data.next_post)

      const title = `${res.data.current_post.title} - 청담 건축사사무소`
      setMetaTags({
        title: title,
        description: res.data.Content,
      })

      const titleElement = document.getElementsByTagName('title')[0]
      titleElement.innerHTML = title
    } catch (err) {
      switch (err.response.status) {
        case 404:
          alert('해당 글이 없습니다.')
          navigate('/works/')
          break
        default:
          alert('알 수 없는 에러가 발생했습니다. 잠시 후 다시 시도해주세요.')
          navigate('/works')
      }
    }
  }, [setPost, id])

  React.useEffect(() => {
    fetchPost()
  }, [id])
  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: 'glow',
        },
      }}
    >
      <Scroll>
        <Container>
          <Navbar fixMenuIdx={1} fixSubmenuIdx={2} setScroll={setScroll} />
          <ContentContainer style={enableScroll ? null : { display: 'none' }}>
            <Content>
              <PostInfo>
                <Title>{post.title}</Title>
                <SubInfo>
                  <div>{post.created_date}</div>
                  <div>{POST_TYPE_KOR_DICT[post.type]}</div>
                </SubInfo>
              </PostInfo>
              <PostContent>
                <div
                  dangerouslySetInnerHTML={{ __html: post.processed_content }}
                />
              </PostContent>

              <PrevNextPost>
                {prevPost.title !== undefined ? (
                  <div
                    className="active"
                    onClick={() => navigate(`/about/board/${prevPost.id}`)}
                  >
                    <div>
                      <KeyboardArrowUpIcon /> 이전글
                    </div>
                    <div className="title">{prevPost.title}</div>
                  </div>
                ) : (
                  <div>
                    <KeyboardArrowUpIcon /> 이전글
                  </div>
                )}
              </PrevNextPost>
              <PrevNextPost>
                {nextPost.title !== undefined ? (
                  <div
                    className="active"
                    onClick={() => navigate(`/about/board/${nextPost.id}`)}
                  >
                    <div>
                      <KeyboardArrowDownIcon /> 다음글
                    </div>
                    <div className="title">{nextPost.title}</div>
                  </div>
                ) : (
                  <div>
                    <KeyboardArrowDownIcon /> 다음글
                  </div>
                )}
              </PrevNextPost>
            </Content>

            <StyledLink to="/about/board">
              <ExitButton>목&nbsp;&nbsp;&nbsp;&nbsp;록</ExitButton>
            </StyledLink>
          </ContentContainer>
          <FooterBar style={enableScroll ? null : { display: 'none' }} />
        </Container>
      </Scroll>
    </Scrollbar>
  )
}

const Scroll = styled.div`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: fixed;
  height: 100vh;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const Container = styled.div`
  height: auto;
  min-height: 700px;
`

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: max(4rem, 40px);
  padding: max(6rem, 40px) 12rem;
  font-family: score;

  align-items: center;

  @media (max-width: 920px) {
    padding: max(4rem, 40px) 6rem;
  }
`

const Content = styled.div`
  width: 100%;
  border-top: 3px solid var(--primary);
  border-bottom: 3px solid var(--primary);
`

const PostInfo = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 2px solid var(--light-300);
  gap: max(1rem, 16px);
  padding: max(1.5rem, 24px) max(2rem, 18px);
`

const Title = styled.div`
  color: black;
  font-size: max(1.2rem, 19px);
  line-height: max(1.6rem, 28px);
  font-weight: 500;
  text-align: center;

  @media (max-width: 400px) {
    text-align: left;
  }
`

const SubInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: max(0.8rem, 14px);
  gap: 1rem;
`

const PostContent = styled.div`
  font-size: max(1rem, 16px);
  line-height: max(1.5rem, 28px);
  white-space: pre-line;
  padding: max(4rem, 56px) max(2rem, 18px) !important;
`

const PrevNextPost = styled.div`
  border-top: 2px solid var(--light-300);
  * {
    font-size: max(0.9rem, 16px) !important;
  }

  div {
    display: flex;
    align-items: center;
  }

  .active {
    cursor: pointer;
    display: flex;
    gap: max(2rem, 20px);
    color: black;
  }

  .active > .title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  padding: max(1rem, 14px) max(2rem, 36px);
  white-space: nowrap;
`

const ExitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: max(11rem, 160px);
  height: max(3rem, 50px);

  background: var(--primary);
  color: white;

  font-size: max(0.8rem, 16px);
`

export default BoardDetail
