import { KeyboardArrowDown } from '@mui/icons-material'
import Menu from '@mui/material/Menu'

import React, { useCallback } from 'react'
import styled from 'styled-components'
import FilterGroups from './FilterMenu/FilterGroups'
import ViewButton from './ViewButton'

import RefreshIcon from '@mui/icons-material/Refresh'
import SearchIcon from '@mui/icons-material/Search'
import { useMediaQuery } from 'react-responsive'
import { Dialog } from '@mui/material'

import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { type } from '@testing-library/user-event/dist/type'

const typeFilters = [
  { label: '초고층/주거시설', value: 'SKYSCRAPER/RESIDENCE' },
  { label: '에너지/인프라시설', value: 'ENERGY/INFRA' },
  { label: '업무/공공시설', value: 'BUSINESS/PUBLIC' },
  { label: '의료/연구시설', value: 'MEDICAL/RESEARCH' },
  { label: '교육/문화시설', value: 'EDUCATION/CULTURE' },
  { label: '스포츠/레저시설', value: 'SPORT/LEISURE' },
  { label: '리모델링/재개발', value: 'RENOVATION/REDEVELOP' },
  { label: '기타', value: 'others' },
]

const regionFilters = [
  { label: '서울', value: 'SEOUL' },
  { label: '경기', value: 'GYEONGGI-DO' },
  { label: '인천', value: 'INCHEON' },
  { label: '강원', value: 'GANGWON-DO' },
  { label: '대전', value: 'DAEJEON' },
  { label: '충청', value: 'CHUNGCHEONG-DO' },
  { label: '대구', value: 'DAEGU' },
  { label: '경상', value: 'GYEONGSANG-DO' },
  { label: '광주', value: 'GWANJU' },
  { label: '전라', value: 'JEOLLA-DO' },
  { label: '울산', value: 'ULSAN' },
  { label: '제주', value: 'JEJU-DO' },
  { label: '부산', value: 'BUSAN' },
  { label: '기타', value: 'others' },
]

function FilterMenu({ isActive, setActive, workData, setUseData }) {
  const [isOpen, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [typeFilter, setTypeFilter] = React.useState(null)
  const [yearFilter, setYearFilter] = React.useState(null)
  const [regionFilter, setRegionFilter] = React.useState(null)

  const [yearFilters, setYearFilters] = React.useState([])

  const isUseDialog = useMediaQuery({ query: '(max-width: 1080px)' })
  const isMoblie = useMediaQuery({ query: '(max-width: 560px)' })

  const open = event => {
    setOpen(true)

    if (isOpen) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const getYearFilters = () => {
    const years = []
    const result = []

    workData.map((work, index) => {
      const designYear = work.design_date.substring(0, 4)
      years.push(designYear)
    })
    const uniqueYears = [...new Set(years)]

    uniqueYears.map((designYear, index) => {
      const label =
        index === 0
          ? `${designYear} ~`
          : `${designYear} ~ ${parseInt(designYear) + 1}`
      result.push({ value: designYear, label: label })
    })

    setYearFilters(result)
  }

  const handleCancel = () => {
    setTypeFilter(null)
    setYearFilter(null)
    setRegionFilter(null)
  }

  React.useEffect(() => {
    getYearFilters()
  }, [workData])

  const applyFilter = React.useCallback(() => {
    const filteredData = workData.filter(
      work =>
        (typeFilter ? work.type === typeFilter : true) &&
        (yearFilter ? work.design_date.substring(0, 4) === yearFilter : true) &&
        (regionFilter ? work.region === regionFilter : true)
    )
    setUseData(filteredData)
    setActive(true)
    setOpen(false)
  }, [regionFilter, typeFilter, yearFilter, setActive, setUseData, workData])

  const filterMenuContent = (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
      <FilterGroupContainer
        style={isMoblie ? { flexDirection: 'column' } : null}
      >
        <FilterGroups
          label="건물용도"
          filters={typeFilters}
          value={typeFilter}
          setValue={setTypeFilter}
        />
        <FilterGroups
          label="설계년도"
          filters={yearFilters}
          value={yearFilter}
          setValue={setYearFilter}
        />
        <FilterGroups
          label="지역"
          filters={regionFilters}
          value={regionFilter}
          setValue={setRegionFilter}
          columnNum={isMoblie ? 3 : 2}
        />
      </FilterGroupContainer>
      <ButtonContainer>
        <div className="cancel" onClick={handleCancel}>
          <span>선택취소</span>
          <RefreshIcon />
        </div>
        <div className="submit" onClick={applyFilter}>
          <span>검색하기</span>
          <SearchIcon />
        </div>
      </ButtonContainer>
    </div>
  )

  return (
    <div>
      <ViewButton isActive={isActive || isOpen} onClick={open}>
        <ButtonContent>
          <div>FILTER</div>
          <KeyboardArrowDown sx={{ fontSize: 'max(1rem, 28px)' }} />
        </ButtonContent>
      </ViewButton>
      {isUseDialog ? (
        <Dialog fullScreen open={isOpen}>
          <AppBar sx={{ position: 'relative', padding: '10px' }} elevation={0}>
            <Toolbar>
              <IconButton
                edge="start"
                color="white"
                onClick={() => setOpen(false)}
                aria-label="close"
              >
                <CloseIcon sx={{ fontSize: '32px' }} />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, fontSize: '24px' }}
                variant="h6"
                component="div"
              >
                FILTER
              </Typography>
            </Toolbar>
          </AppBar>

          {filterMenuContent}
        </Dialog>
      ) : (
        <StyledMenu
          anchorEl={anchorEl}
          open={isOpen}
          onClose={() => setOpen(false)}
        >
          {filterMenuContent}
        </StyledMenu>
      )}
    </div>
  )
}

const ButtonContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    box-shadow: none !important;
    border-radius: 0 !important;
    overflow: hidden;
  }

  & .MuiList-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
  }
`

const FilterGroupContainer = styled.div`
  flex: 1;
  display: flex !important;
  justify-content: space-around;
  gap: max(2rem, 40px);
  padding: max(2rem, 30px);
  border: 2px solid var(--light-600);
  border-bottom: none;
`

const ButtonContainer = styled.div`
  display: flex;
  font-family: score;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: max(1rem, 16px);
    width: 50%;
    cursor: pointer;
    gap: max(0.5rem, 8px);
  }

  * {
    font-size: max(1rem, 18px) !important;
  }

  .cancel {
    background: var(--light-600);
    color: black;
  }

  .submit {
    background: var(--primary);
    color: white;
  }
`

export default FilterMenu
