import { Scrollbar } from 'smooth-scrollbar-react'
import styled from 'styled-components'

function ScrollBody({ children }) {
  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: 'glow',
        },
      }}
    >
      <Container>{children}</Container>
    </Scrollbar>
  )
}

const Container = styled.div`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100vh;
  position: fixed;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

export default ScrollBody
