import styled from 'styled-components'
import PropTypes from 'prop-types'

function LogoOverlay({ opacity }) {
  return <Container style={{ opacity: opacity }}>Cheongdam</Container>
}

LogoOverlay.propTypes = {
  opacity: PropTypes.number.isRequired,
}

export default LogoOverlay

const Container = styled.div`
  font-family: twaySky;
  letter-spacing: 1.2rem;
  font-size: max(7rem, 80px);

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
