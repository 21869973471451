import { Link } from 'react-router-dom'
import styled from 'styled-components'
import StyledLink from '../../StyledLink'
import React from 'react'
import PropTypes from 'prop-types'

function ViewMoreBtn({ target }) {
  const [isHover, setHover] = React.useState(false)

  return (
    <Container
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <StyledLink to={target}>
        <IndicatorContainer>
          <Indicator className={isHover ? 'scale-up-ver-bottom' : null} />
        </IndicatorContainer>
        <Text className={isHover ? 'bold' : null}>VIEW MORE</Text>
      </StyledLink>
    </Container>
  )
}

ViewMoreBtn.porpTypes = {
  target: PropTypes.string,
}

const Container = styled.div`
  a {
    display: flex;
    align-items: flex-end;
  }

  .bold {
    font-weight: 400;
  }
`

const IndicatorContainer = styled.div`
  padding-bottom: 6px;
`
const Indicator = styled.div`
  width: max(2rem, 32px);
  height: 2px;
  background: black;
  margin-right: max(0.5rem, 8px);
  line-height: max(1.6rem, 24px);
`
const Text = styled.div`
  font-family: score;
  font-weight: 200;
  font-size: max(1rem, 16px);
  line-height: max(1.6rem, 24px);
  color: black;
`

export default ViewMoreBtn
