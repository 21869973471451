import PropTypes from 'prop-types'
import StyledLink from '../../StyledLink'
import React from 'react'

function NavItem({ target, isNavHover, children }) {
  return (
    <StyledLink
      to={target}
      style={isNavHover ? { color: 'var(--primary)' } : { color: 'white' }}
    >
      {children}
    </StyledLink>
  )
}

NavItem.propTypes = {
  target: PropTypes.string.isRequired,
  isNavHover: PropTypes.bool.isRequired,
}

export default React.memo(NavItem)
