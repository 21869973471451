import styled from 'styled-components'
import React from 'react'

import Navbar from '../components/Navbar/Navbar'
import FooterBar from '../components/FooterBar/FooterBar'
import ScrollBody from '../components/ScrollBody'
import setMetaTags from '../utils/setMetaTags'

function InfoWidget({ title, label, children }) {
  return (
    <InfoWidgetContainer>
      <div className="title">{title}</div>
      <div>
        <span className="label">{label} </span>
        <span className="value">{children}</span>
      </div>
    </InfoWidgetContainer>
  )
}

const InfoWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: max(1.1rem, 18px);
  white-space: nowrap;

  .title {
    color: var(--primary);
    font-weight: 500;
    font-size: max(1.3rem, 24px);
  }

  .label {
    font-weight: 500;
    color: var(--light-800);
  }
`

function Directions() {
  const [enableScroll, setScroll] = React.useState(true)

  React.useEffect(() => {
    const title = '오시는 길 - 청담 건축사사무소'
    setMetaTags({
      title: title,
      description: '청담 건축사사무소에 오는 길을 안내하는 페이지입니다.',
    })

    const titleElement = document.getElementsByTagName('title')[0]
    titleElement.innerHTML = title

    return () => {
      setMetaTags({})
      titleElement.innerHTML = '청담 건축사사무소'
    }
  }, [])
  return (
    <ScrollBody>
      <Container>
        <Navbar fixMenuIdx={3} fixSubmenuIdx={0} setScroll={setScroll} />
        <ContentContainer style={enableScroll ? null : { display: 'none' }}>
          <div className="title">본사 본관</div>
          <InfoContainer>
            <InfoWidget title="Address" label="27738">
              충청북도 음성군 맹동면 대하2가길 31, 6층 601호(도시메디컬타워)
            </InfoWidget>
            <InfoWidget title="TEL" label="+82">
              043.882.7901
            </InfoWidget>
            <InfoWidget title="FAX" label="+82">
              043.882.7902
            </InfoWidget>
          </InfoContainer>

          <MapContainer>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.332796332526!2d127.54171791563523!3d36.90630666941865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3564c7ef256a3c9b%3A0x6e1c2cae416b5aa!2z7LKt64u06rG07LaV7IKs7IKs66y07IaM!5e0!3m2!1sko!2skr!4v1670238666155!5m2!1sko!2skr"
              width="100%"
              height="max(100%, 600px)"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </MapContainer>
        </ContentContainer>
        <FooterBar style={enableScroll ? null : { display: 'none' }} />
      </Container>
    </ScrollBody>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: max(2.5rem, 40px);
  padding: max(4rem, 40px) 12rem;

  @media (max-width: 1800px) {
    padding: max(4rem, 40px) 6rem;
  }

  font-family: score;

  > .title {
    font-weight: 600;
    font-size: max(2.5rem, 36px);
    color: var(--primary);
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: max(6rem, 40px);
`

const MapContainer = styled.div`
  width: 100%;

  iframe {
    width: 100% !important;
    height: max(40rem, 400px) !important;
  }

  .map_border {
    display: none !important;
  }
`

export default Directions
