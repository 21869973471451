import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import StyledLink from './StyledLink'

function WorkItem({
  index,
  image,
  title,
  desc,
  style,
  className,
  isDummy = false,
}) {
  const [isHover, setHover] = React.useState(false)
  return (
    <StyledLink
      to={isDummy ? null : `/works/${index}`}
      className={className}
      style={style}
    >
      <Container
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ backgroundImage: `url(${image})` }}
      >
        {isHover ? <Overlay className="fade-in-3" /> : null}
        {isHover ? (
          <Info className="fade-in-bottom">
            <div className="title">{title}</div>
            <div className="desc">{desc}</div>
          </Info>
        ) : null}
      </Container>
    </StyledLink>
  )
}

WorkItem.propTypes = {
  index: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDummy: PropTypes.bool,
}

const Container = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: calc(1 / 2.085714285714286 * 100%);
  position: relative;
  background-size: cover;
  background-position: center center;
`

const Info = styled.div`
  position: absolute;
  bottom: max(1.5rem, 21px);
  left: max(1rem, 16px);

  font-family: score;
  color: white;

  .title {
    font-weight: 200;
    font-size: max(1.1rem, 18px);
    line-height: max(1.4rem, 21px);
  }

  .desc {
    font-weight: 100;
    font-size: max(0.9rem, 14px);
  }

  @media (max-width: 750px) {
    .title {
      font-size: 16px;
      line-height: 21px;
    }

    .desc {
      font-size: 12px;
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 10%,
    rgba(0, 0, 0, 0.3) 35%,
    rgba(25, 25, 25, 0.1) 60%,
    rgba(0, 0, 0, 0) 100%
  );
`

export default WorkItem
