import styled from 'styled-components'
import React from 'react'

import Navbar from '../components/Navbar/Navbar'
import FooterBar from '../components/FooterBar/FooterBar'
import ScrollBody from '../components/ScrollBody'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import FolderIcon from '@mui/icons-material/Folder'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useMediaQuery } from 'react-responsive'
import setMetaTags from '../utils/setMetaTags'
import axios from 'axios'
import Loading from '../components/Loading'
import api from '../utils/api'

function UploadButton({ onChange }) {
  return (
    <div>
      <Button
        disableElevation={true}
        variant="contained"
        component="label"
        endIcon={
          <DriveFolderUploadIcon
            sx={{ fontSize: 'max(1.3rem, 21px) !important' }}
          />
        }
        sx={{
          fontFamily: 'score',
          fontSize: 'max(1.1rem, 18px)',
        }}
      >
        파일 업로드
        <input hidden multiple type="file" onChange={onChange} />
      </Button>
      <Warning>3MB 이하 파일만 업로드 가능합니다.</Warning>
    </div>
  )
}

const Warning = styled.div`
  font-family: score;
  font-size: max(0.7rem, 13px);
  padding-top: max(0.5rem, 9px);
  color: var(--light-800);
`

function UploadFileList({ fileList, onDelete }) {
  const isMoblie = useMediaQuery({ query: '(max-width: 550px)' })
  return (
    <List>
      {fileList.map((file, index) => {
        return (
          <ListItem
            key={index}
            disablePadding
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onDelete(index)}
              >
                <DeleteIcon
                  sx={{
                    fontSize: 'max(2rem, 32px)',
                    color: 'var(--primary)',
                  }}
                />
              </IconButton>
            }
            sx={{
              borderBottom: '2px solid var(--light-600)',
              paddingBottom: 'max(0.5rem, 9px)',
              marginBottom: 'max(1rem, 18px)',
            }}
          >
            {isMoblie ? null : (
              <ListItemIcon sx={{ padding: 'max(0.5rem, 10px)' }}>
                <FolderIcon sx={{ fontSize: 'max(2rem, 32px)' }} />
              </ListItemIcon>
            )}
            <ListItemText
              sx={{
                span: {
                  fontFamily: 'score !important',
                  fontSize: 'max(1.2rem, 18px)',
                },
                '.MuiListItemText-secondary': {
                  fontSize: 'max(1rem, 16px) !important',
                },
              }}
              primary={file.name}
              secondary={`${Math.floor(file.size / 1024).toLocaleString()}KiB`}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

const INQUIRY_INFO_INIT_STATE = {
  first_name: '',
  last_name: '',
  organization: '',
  email: '',
  phone_number: '',
  title: '',
  content: '',
}

const INPUT_VALIDITY_INIT_STATE = {
  first_name: false,
  last_name: false,
  organization: false,
  email: false,
  phone_number: false,
  title: false,
  content: false,
}

function Inquiry() {
  const [isLoading, setLoading] = React.useState(false)

  const [enableScroll, setScroll] = React.useState(true)
  const [checked, setChecked] = React.useState(false)

  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const [inquiryInfo, setInquiryInfo] = React.useState(INQUIRY_INFO_INIT_STATE)

  const [uploadFiles, setUploadFiles] = React.useState([])

  const [inputValidity, setInputValidity] = React.useState(
    INPUT_VALIDITY_INIT_STATE
  )

  const handleFileAdd = e => {
    const files = e.target.files

    if (uploadFiles === []) {
      setUploadFiles(files)
    } else {
      if (uploadFiles.length + files.length > 3) {
        alert('첨부파일은 최대 3개까지 가능합니다.')
        return
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].size / (1024 * 1024) > 3) {
          alert('3MB 이하 파일만 업로드 가능합니다.')
          continue
        }
        setUploadFiles(prev => [].concat(prev, files[i]))
      }
    }
  }

  const handleFileDelete = index => {
    setUploadFiles(prev => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  const handleCheck = event => {
    setChecked(event.target.checked)
  }

  React.useEffect(() => {
    const title = '문의하기 - 청담 건축사사무소'
    setMetaTags({
      title: title,
      description: '청담 건축사사무소의 문의 페이지입니다.',
    })

    const titleElement = document.getElementsByTagName('title')[0]
    titleElement.innerHTML = title

    return () => {
      setMetaTags({})
      titleElement.innerHTML = '청담 건축사사무소'
    }
  }, [])

  const checkInput = React.useCallback(
    (type, value) => {
      const nextInputValidity = Object.assign({}, inputValidity)

      if (value.length > 100) {
        nextInputValidity[type] = false
      } else {
        nextInputValidity[type] = true
      }

      setInputValidity(nextInputValidity)
    },
    [inputValidity]
  )

  const checkEmail = React.useCallback(
    value => {
      const nextInputValidity = Object.assign({}, inputValidity)
      // @, .이 포함되고 한글이 없을 때 유효성 검사 통과
      if (
        value.indexOf('@') === -1 ||
        value.indexOf('.') === -1 ||
        /[ㄱ-ㅎㅏ-ㅣ가-힣]/g.test(value) ||
        value.length > 255
      ) {
        nextInputValidity.email = false
      } else {
        nextInputValidity.email = true
      }

      setInputValidity(nextInputValidity)
    },
    [inputValidity]
  )

  const checkPhoneNumber = React.useCallback(
    value => {
      const nextInputValidity = Object.assign({}, inputValidity)
      if (isNaN(value) || value.length > 50) {
        nextInputValidity.phone_number = false
      } else {
        nextInputValidity.phone_number = true
      }

      setInputValidity(nextInputValidity)
    },
    [inputValidity]
  )

  const checkTitle = React.useCallback(
    value => {
      const nextInputValidity = Object.assign({}, inputValidity)
      if (!(value.length >= 3 && value.length <= 100)) {
        nextInputValidity.title = false
      } else {
        nextInputValidity.title = true
      }
      setInputValidity(nextInputValidity)
    },
    [inputValidity]
  )

  const checkContent = React.useCallback(
    value => {
      const nextInputValidity = Object.assign({}, inputValidity)
      if (!(value.length >= 10 && value.length <= 2000)) {
        nextInputValidity.content = false
      } else {
        nextInputValidity.content = true
      }
      setInputValidity(nextInputValidity)
    },
    [inputValidity]
  )

  const handleInput = (e, type) => {
    const nextInquriyInfo = Object.assign({}, inquiryInfo)
    nextInquriyInfo[type] = e.target.value
    setInquiryInfo(nextInquriyInfo)
  }

  const handleSubmit = React.useCallback(async () => {
    setIsSubmitted(true)

    checkEmail(inquiryInfo.email)
    checkTitle(inquiryInfo.title)
    checkContent(inquiryInfo.content)

    if (Object.values(inputValidity).includes(false)) {
      alert('값이 유효하지 않습니다. 다시 확인해주세요.')
      window.scrollTo(0, 0)
      return
    }

    const formData = new FormData()
    uploadFiles.forEach(file => formData.append('files', file))
    Object.keys(inquiryInfo).forEach(key =>
      formData.append(key, inquiryInfo[key])
    )

    formData.append('enctype', 'multipart/form-data')
    try {
      setLoading(true)
      const res = await api.post('/inquiry/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setLoading(false)

      console.log(res)
      switch (res.status) {
        case 201:
          alert('전송이 성공했습니다.')
          window.location.reload()
          break
        default:
          alert('알 수 없는 에러가 발생했습니다. 잠시 후 다시 시도해주세요.')
          window.location.reload()
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
      switch (err.response.status) {
        case 400:
        case 403:
          alert('문의 전송에 에러가 발생했습니다.')
          window.location.reload()
          break
        default:
          alert('알 수 없는 에러가 발생했습니다. 잠시 후 다시 시도해주세요.')
          window.location.reload()
      }
    }
  }, [
    inquiryInfo,
    inputValidity,
    uploadFiles,
    checkContent,
    checkEmail,
    checkTitle,
  ])

  return (
    <div>
      <Loading isLoading={isLoading} />
      <ScrollBody>
        <Container>
          <Navbar fixMenuIdx={3} fixSubmenuIdx={1} setScroll={setScroll} />
          <ContentContainer style={enableScroll ? null : { display: 'none' }}>
            <Title>문의할 내용을 입력해 주세요.</Title>

            <StyledBox component="form" noValidate autoComplete="off">
              <InputContainer>
                <TextFlexRow>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gap: '2rem',
                    }}
                  >
                    <StyledTextField
                      error={
                        (isSubmitted || inquiryInfo.first_name.length > 0) &&
                        !inputValidity.first_name
                          ? true
                          : false
                      }
                      helperText="50자 이하로 작성해주세요."
                      id="first-name"
                      label="이름 (First Name)"
                      placeholder="길동"
                      variant="standard"
                      onChange={e => {
                        handleInput(e, 'first_name')
                        checkInput('first_name', e.target.value)
                      }}
                    />
                    <StyledTextField
                      error={
                        (isSubmitted || inquiryInfo.last_name.length > 0) &&
                        !inputValidity.last_name
                          ? true
                          : false
                      }
                      helperText="50자 이하로 작성해주세요."
                      id="last-name"
                      label="성 (Last Name)"
                      placeholder="홍"
                      variant="standard"
                      onChange={e => {
                        handleInput(e, 'last_name')
                        checkInput('last_name', e.target.value)
                      }}
                    />
                  </div>
                  <StyledTextField
                    error={
                      (isSubmitted || inquiryInfo.organization.length > 0) &&
                      !inputValidity.organization
                        ? true
                        : false
                    }
                    helperText="100자 이하로 작성해 주세요."
                    id="organization"
                    label="소속 (Organization)"
                    placeholder="개인이면 '없음'이라 기재해 주세요."
                    variant="standard"
                    onChange={e => {
                      handleInput(e, 'organization')
                      checkInput('organization', e.target.value)
                    }}
                  />
                </TextFlexRow>

                <TextFlexRow>
                  <StyledTextField
                    error={
                      (isSubmitted || inquiryInfo.email.length > 0) &&
                      !inputValidity.email
                        ? true
                        : false
                    }
                    helperText="이메일 형식에 맞춰 입력하세요."
                    id="email"
                    label="이메일 (E-Mail)"
                    placeholder="example@example.com"
                    variant="standard"
                    onChange={e => {
                      handleInput(e, 'email')
                      checkEmail(e.target.value)
                    }}
                  />

                  <StyledTextField
                    error={
                      (isSubmitted || inquiryInfo.phone_number.length > 0) &&
                      !inputValidity.phone_number
                        ? true
                        : false
                    }
                    helperText="하이픈(-)은 빼고 숫자로만 입력하세요."
                    id="tel"
                    label="연락처 (Phone Number)"
                    placeholder="010-0000-0000"
                    variant="standard"
                    onChange={e => {
                      handleInput(e, 'phone_number')
                      checkPhoneNumber(e.target.value)
                    }}
                  />
                </TextFlexRow>

                <StyledTextField
                  error={
                    (isSubmitted || inquiryInfo.title.length > 0) &&
                    !inputValidity.title
                      ? true
                      : false
                  }
                  helperText="3자 이상 100자 이하로 작성해 주세요."
                  id="title"
                  label="제목 (Inquiry Title)"
                  placeholder="문의내용이 잘 나타나도록 간단하게 요약해 주세요."
                  variant="standard"
                  onChange={e => {
                    handleInput(e, 'title')
                    checkTitle(e.target.value)
                  }}
                />
              </InputContainer>

              <TextField
                error={
                  (isSubmitted || inquiryInfo.content.length > 0) &&
                  !inputValidity.content
                    ? true
                    : false
                }
                helperText="10자 이상 2000자 이하로 작성해 주세요."
                id="content"
                label="문의내용 (Inquiry Content)"
                multiline
                rows={8}
                placeholder="문의내용을 자세하게 입력해 주세요."
                onChange={e => {
                  handleInput(e, 'content')
                  checkContent(e.target.value)
                }}
                sx={{
                  '.MuiFormHelperText-root': {
                    fontFamily: 'score',
                    fontSize: 'max(0.8rem, 16px)',
                  },
                }}
              />

              <UploadButton onChange={handleFileAdd} />
              <UploadFileList
                fileList={uploadFiles}
                onDelete={handleFileDelete}
              />
              <AgreementGuide>
                <div>개인정보 수집 및 이용에 대한 안내</div>
                <ul className="list">
                  <li>
                    <span className="label">수집항목: </span>
                    <span className="value">
                      이름, 소속, 전화번호, 이메일, 문의내용
                    </span>
                  </li>
                  <li>
                    <span className="label">수집목적: </span>
                    <span className="value">
                      문의내용 내 사실확인에 따른 연락, 문의내용 회신
                    </span>
                  </li>
                  <li>
                    <span className="label">보유기간: </span>
                    <span className="value">1년 (요청 시 즉시파기)</span>
                  </li>
                </ul>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheck}
                      sx={{ svg: { fontSize: 'max(1.5rem, 24px)' } }}
                    />
                  }
                  sx={{
                    padding: 'max(1rem, 18px) 0',
                    '.MuiTypography-root': {
                      fontFamily: 'score',
                      color: 'black !important',
                      fontSize: 'max(1.1rem, 16px)',
                    },
                  }}
                  label="'개인정보 수집 및 이용에 대한 안내'를 읽었으며, 상기 내용에 동의합니다."
                />
              </AgreementGuide>

              <SubmitButton
                active={checked}
                onClick={() => {
                  if (checked) {
                    handleSubmit()
                  }
                }}
              >
                문 의 하 기
              </SubmitButton>
            </StyledBox>
          </ContentContainer>
          <FooterBar style={enableScroll ? null : { display: 'none' }} />
        </Container>
      </ScrollBody>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: max(3rem, 40px);
  padding: max(4rem, 40px) 20rem;
  font-family: score;

  @media (max-width: 1800px) {
    padding: max(4rem, 40px) 6rem;
  }
`

const Title = styled.div`
  font-size: max(1.8rem, 24px);
  font-weight: 500;
  color: var(--primary);
`

const TextFlexRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8rem;

  > div {
    position: relative;
    width: auto !important;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    gap: max(5rem, 40px);
  }
`

const StyledTextField = styled(TextField)`
  width: 100%;

  .MuiFormHelperText-root {
    font-family: score;
    font-size: max(0.8rem, 16px);
  }
`

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: max(2rem, 40px);

  .MuiInputBase-root {
    margin-top: 24px;
    font-family: score !important;
    font-size: max(1.2rem, 18px);
  }

  .MuiInputBase-multiline {
    margin-top: 4px !important;
  }

  .MuiFormLabel-root {
    font-family: score !important;
    font-size: max(1.2rem, 18px);
  }

  > .MuiFormControl-root {
    margin-top: max(2.1rem, 24px);
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: max(3rem, 40px);
`

const AgreementGuide = styled.div`
  display: flex;
  flex-direction: column;

  font-family: score;
  font-weight: 500;
  font-size: max(1.3rem, 20px);

  color: var(--primary);
  margin-top: max(4rem, 36px);

  .list {
    font-size: max(1.1rem, 16px);
    line-height: max(2rem, 28px);
    padding: max(1.3rem, 21px) 0;

    list-style-type: disc;
    list-style-position: inside;
    border-bottom: 3px solid var(--light-600);
  }

  .list > li {
    display: flex;
    gap: max(0.5rem, 9px);
  }

  .list .label {
    white-space: nowrap;
  }

  .list .value {
    font-weight: 400;
    color: black;
  }
`

const SubmitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.active ? 'pointer' : 'not-allowed')};

  width: max(15rem, 180px);
  height: max(4rem, 60px);

  background: ${props =>
    props.active ? 'var(--primary)' : 'var(--light-700)'};
  color: white;

  font-family: score;
  font-size: max(1.3rem, 21px);

  margin: 0 auto;
`

export default Inquiry
