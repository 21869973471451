import styled from 'styled-components'
import { Button, IconButton } from '@mui/material'
import PropTypes from 'prop-types'

import LogoOverlay from '../LogoOverlay'
import SectionInfo from '../SectionInfo'

import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import React from 'react'

import { useInView } from 'react-intersection-observer'
import StyledLink from '../../../StyledLink'

function ContactButton({ title, icon, link, children }) {
  return (
    <ContactButtonContainer color="black" startIcon={icon}>
      <StyledLink to={link}>
        <Title>
          {title}
          <Description>{children}</Description>
        </Title>
      </StyledLink>
    </ContactButtonContainer>
  )
}

const ContactButtonContainer = styled(Button)`
  font-family: score !important;
  justify-content: flex-start !important;
  svg {
    font-size: max(2rem, 39px) !important;
  }
  @media (max-width: 800px) {
    svg {
      font-size: 36px !important;
    }
  }
`

const Title = styled.div`
  text-align: left !important;
  font-weight: 600 !important;
  font-size: max(1rem, 16px) !important;
  line-height: max(1.2rem, 21px) !important;
  margin-left: max(0.4rem, 7px);
  color: black;

  @media (max-width: 800px) {
    font-size: 14px !important;
    line-height: 18px !important;
    margin-left: 2px;
  }
`

const Description = styled.div`
  font-weight: 300 !important;
  font-size: max(0.8rem, 15px) !important;
  @media (max-width: 800px) {
    font-size: 12px !important;
    line-height: 16px !important;
  }
`

function ContactUs() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  })

  const addr = '충청북도 음성군 맹동면 대하2가길 31, 6층 601호(도시메디컬타워)'
  const handleCopyClipBoard = async text => {
    try {
      await navigator.clipboard.writeText(text)
      alert('클립보드에 링크가 복사되었습니다.')
    } catch (e) {
      alert('복사에 실패하였습니다')
    }
  }

  return (
    <Container ref={ref}>
      <ContactContainer>
        <Contact style={inView ? { opacity: '1' } : { opacity: '0' }}>
          <SectionInfo
            title="Contact us"
            className={inView ? 'fade-in-bottom-1-2' : null}
          >
            <Address>
              {addr}
              <AddressCopyBtn
                color="black"
                onClick={() => handleCopyClipBoard(addr)}
              >
                <FileCopyOutlinedIcon />
              </AddressCopyBtn>
            </Address>
            <TelNumber>043-882-7901</TelNumber>
          </SectionInfo>

          <ButtonContainer
            className={inView ? 'fade-in-bottom-1-2' : null}
            style={{ animationDelay: '0.3s' }}
          >
            <ContactButton
              title={'회사소개'}
              link="/about/overview"
              icon={<HomeWorkOutlinedIcon />}
            >
              사무소에 대해 자세히 알고싶다면?
            </ContactButton>
            <div style={{ height: 'max(1.2rem, 20px)' }} />
            <ContactButton
              title={'오시는 길'}
              link="/contact/directions"
              icon={<MapOutlinedIcon />}
            >
              고객님의 편한 방문을 소원합니다.
            </ContactButton>
            <div style={{ height: 'max(1.2rem, 20px)' }} />
            <ContactButton
              title="고객문의(E-Mail)"
              link="/contact/inquiry"
              icon={<EmailOutlinedIcon />}
            >
              언제든 편하게 문의주세요.
            </ContactButton>
          </ButtonContainer>
        </Contact>
      </ContactContainer>

      <MapContainer>
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3190.332884147529!2d127.54171631563517!3d36.90630456941876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3564c71a32d2641f%3A0x8c1fe6a73a4d9e19!2z7LKt64u06rG07LaV7IKs7IKs66y07IaM!5e0!3m2!1sko!2skr!4v1673504433745!5m2!1sko!2skr"
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </MapContainer>
      <OverlayContainer>
        <LogoOverlay opacity={0.02} />
      </OverlayContainer>
    </Container>
  )
}
export default ContactUs

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @media (max-height: 800px), (max-width: 450px) {
    height: auto;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    height: auto;
  }
`

const ContactContainer = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  width: 40%;
  height: 100%;

  background: var(--light-200);

  @media (max-width: 750px) {
    width: 50%;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  gap: max(7rem, 150px);

  @media (max-width: 950px) {
    width: 85%;
  }
  @media (max-width: 800px) {
    width: 85%;
    flex-direction: column;
  }

  @media (max-height: 800px), (max-width: 450px) {
    gap: 40px;
  }

  justify-content: space-between;
  padding-left: max(40px, 3.5rem);
  padding-right: 10px;

  @media (max-height: 800px), (max-width: 450px) {
    padding: 40px;
  }
`

const Address = styled.div`
  margin-top: max(2rem, 40px);
  width: 80%;
  word-break: keep-all;
  @media (max-width: 800px) {
    margin-top: 20px;
    width: 100%;
  }
`

const AddressCopyBtn = styled(IconButton)`
  margin-left: 0.2em !important;
  svg {
    font-size: max(1.2rem, 18px); !important;
  }
`

const TelNumber = styled.div`
  font-family: hanMaum;
  font-size: max(1.55rem, 30px);
  line-height: max(1.7rem, 36px);
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    margin-top: 20px;
    flex-direction: column;
  }
`

const MapContainer = styled.div`
  width: 60%;

  #daumRoughmapContainer1669964074576 {
    position: relative;
    width: 100% !important;
    height: 100% !important;
  }

  .wrap_map {
    width: 100% !important;
    height: 100% !important;
  }

  .map_border {
    display: none !important;
  }

  @media (max-width: 750px) {
    width: 50%;
  }

  @media (max-width: 550px) {
    width: 100%;
    height: 400px;
  }
`

const OverlayContainer = styled.div`
  position: absolute;
  left: 0px;
  transform: rotate(90deg) translate(46%, 350%);
`
