import styled from 'styled-components'

import { useInView } from 'react-intersection-observer'
import { Label } from './Body'

function SectionTwo() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <Container ref={ref}>
      <Title
        className={inView ? 'fade-in-left' : null}
        style={inView ? { opacity: '1' } : { opacity: '0' }}
      >
        <Label color="var(--second)">who we are</Label>
        요람부터 무덤까지.
      </Title>
      <Image className={inView ? 'scale-out' : null} />
      <DescContainer>
        <Description
          className={inView ? 'fade-in-left' : null}
          style={
            inView ? { opacity: '1', animationDelay: '1s' } : { opacity: '0' }
          }
        >
          <span>고객님의 성공적인 건설사업을 위해 계약의 시작부터 끝까지 </span>
          <span>청담이 책임집니다. 청담건축사사무소는 자사의 슬로건인</span>
          <span>'요람부터 무덤까지'을 따라 최우선으로 행동하겠습니다.</span>
        </Description>
      </DescContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  height: max(100vh, 550px);

  font-family: score;
  padding: max(5rem, 60px) 7rem;
  box-sizing: border-box;

  .scale-out {
    animation: AnimateBG 3s ease both;
  }

  @keyframes AnimateBG {
    0% {
      background-position: 50% 60%;
    }
    100% {
      background-position: 50% 50%;
    }
  }
`

const Title = styled.div`
  font-weight: 600;
  font-size: max(5rem, 48px);
  color: var(--primary);

  @media (max-width: 550px) {
    font-size: 36px;
  }
`
const Image = styled.div`
  flex: 1;
  background-image: url('images/overview_two.jpg');
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 50% 60%;
  background-size: cover;

  min-height: 250px;
`

const DescContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const Description = styled.div`
  word-break: keep-all;
  font-weight: 400;
  font-size: max(1.4rem, 18px);
  line-height: max(1.9rem, 26px);
  text-align: right;

  span {
    display: block;
  }

  @media (max-width: 550px) {
    span {
      display: inline !important;
    }
    font-size: 14px;
    line-height: 21px;
  }
`

export default SectionTwo
