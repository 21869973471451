import React from 'react'
import styled from 'styled-components'
import Navbar from '../Navbar/Navbar'
import SectionFive from './SectionFive'
import SectionFour from './SectionFour'
import SectionOne from './SectionOne'
import SectionThree from './SectionThree'
import SectionTwo from './SectionTwo'
import FooterBar from '../FooterBar/FooterBar'

function Body({ enableScroll, setScroll }) {
  return (
    <Container>
      <FlexContainer>
        <Navbar fixMenuIdx={1} fixSubmenuIdx={0} setScroll={setScroll} />
        <SectionOne style={enableScroll ? null : { display: 'none' }} />
      </FlexContainer>
      <div style={enableScroll ? null : { display: 'none' }}>
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <FlexContainer>
          <SectionFive />
          <FooterBar />
        </FlexContainer>
      </div>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100vh;
  @media (max-height: 500px) {
    height: auto;
  }
`

export const Label = styled.div`
  font-family: nanumPen;
  font-size: max(2.2rem, 27px);
  font-weight: 400;
  line-height: max(1.6rem, 18px);
  color: ${props => props.color};
  animation-delay: ${props => props.animationDelay};
`
export default Body
