import { Slide } from '@mui/material'
import styled from 'styled-components'
import LogoOverlay from '../../LogoOverlay'
import SectionInfo from '../../SectionInfo'
import BusinessSlider from './BusinessSlider'
import ResponsiveText from '../../../../ResponsiveText'

import { useInView } from 'react-intersection-observer'
import React from 'react'

function Business() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <Container ref={ref}>
      <OverlayContainer>
        <LogoOverlay opacity={0.05} />
      </OverlayContainer>
      <ContentContainer>
        <Content style={inView ? { opacity: '1' } : { opacity: '0' }}>
          <InfoContainer className={inView ? 'fade-in-bottom-1-2' : ''}>
            <SectionInfo title="BUSINESS">
              <ResponsiveText>청담 건축사사무소는 고객의 </ResponsiveText>
              <ResponsiveText>성공적인 건축사업을 위해 </ResponsiveText>
              <ResponsiveText>전문적인 솔루션을 제공합니다.</ResponsiveText>
            </SectionInfo>
          </InfoContainer>
          <SliderContainer
            className={inView ? 'fade-in-bottom-1-2' : ''}
            style={inView ? { animationDelay: '0.4s' } : null}
          >
            <BusinessSlider />
          </SliderContainer>
        </Content>
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 70%;
  background: var(--light-200);

  @media (max-height: 800px) {
    width: 100%;
    height: 350px;
    padding: 40px 0;
  }
`

const ContentContainer = styled.div`
  display: flex !important;
  height: 100%;

  align-items: center;
  justify-content: center;
`
const Content = styled.div`
  display: flex;
  gap: 5rem;
  width: 100%;
  height: 70%;
  box-sizing: border-box;
  @media (max-height: 800px) {
    height: 100%;
  }
  @media (max-width: 750px) {
    flex-direction: column;
  }
`

const OverlayContainer = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
`

const InfoContainer = styled.div`
  padding-left: 5%;
  @media (max-width: 750px), (max-height: 800px) {
    padding-top: 0;
  }
`

const SliderContainer = styled.div`
  width: 70%;
  height: 100%;
  flex: 1;
  @media (max-width: 750px) {
    padding-left: 5%;
    width: 100%;
    height: 200px !important;
  }
`

export default Business
