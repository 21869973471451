import styled from 'styled-components'
import PropTypes from 'prop-types'

function ViewButton({ isActive, onClick, children }) {
  return (
    <Container
      style={isActive ? { color: 'white', background: 'var(--primary)' } : null}
      onClick={event => onClick(event)}
    >
      {children}
    </Container>
  )
}

ViewButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const Container = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;

  width: max(7rem, 150px);
  height: max(2rem, 60px);
  padding: 0 max(1rem, 18px);

  font-family: score;
  font-size: max(1rem, 18px);
  color: var(--primary);

  border: 1px solid var(--light-600);

  cursor: pointer;

  @media (max-width: 650px) {
    width: auto;
    font-size: 16px;
  }
`

export default ViewButton
