import PropTypes from 'prop-types'
import styled from 'styled-components'
import StyledLink from '../../../StyledLink'
import React from 'react'

function OverviewSubItem({ submenu, isSubMenuOpen }) {
  return (
    <Container style={isSubMenuOpen ? { maxHeight: '300px' } : null}>
      {submenu.map(([title, link], index) => (
        <StyledLink key={index} to={link}>
          <Title>{title}</Title>
        </StyledLink>
      ))}
    </Container>
  )
}

const Title = styled.div`
  font-family: score;
  font-weight: 400;
  color: black;
  margin-top: 20px;

  @media screen and (min-width: 500px) {
    font-size: 18px;
  }

  @media screen and (max-width: 500px) {
    font-size: 0.6em;
  }
`

const Container = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: 0.5s;
`

OverviewSubItem.propTypes = {
  submenu: PropTypes.array.isRequired,
}
export default OverviewSubItem
