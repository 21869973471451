import { Link } from 'react-router-dom'
import styled from 'styled-components'

function StyledLink(props) {
  return <Container {...props} />
}

const Container = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export default StyledLink
