import styled from 'styled-components'
import PropTypes from 'prop-types'

function MainSlide({ name, addr, imgPath }) {
  return (
    <Container>
      <Info>
        <p className="name">{name}</p>
        <p className="addr">{addr}</p>
      </Info>
      <Overlay />
      <Image src={`${imgPath}`} alt={name} />
    </Container>
  )
}

MainSlide.propTypes = {
  name: PropTypes.string.isRequired,
  addr: PropTypes.string.isRequired,
  imgPath: PropTypes.string.isRequired,
}

export const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
`

export const Info = styled.div`
  position: absolute;

  bottom: 50px;
  left: 80px;

  @media screen and (max-width: 500px) {
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
  }

  z-index: 2;
  font-family: score;
  color: white;
  white-space: nowrap;
  p {
    line-height: max(1.3rem, 24px);
  }

  .name {
    font-weight: 300;
    font-size: max(1.2rem, 21px);
  }
  .addr {
    font-weight: 100;
    font-size: max(1rem, 16px);
  }
`

export const Image = styled.img`
  width: 100vw;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
`

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
`

export default MainSlide
