import PropTypes from 'prop-types'

import HeaderSlider from './Slider/HeaderSlider'
import Navbar from '../../Navbar/Navbar'
import styled from 'styled-components'

function Header({ setScroll }) {
  return (
    <Container>
      <NavbarContainer>
        <Navbar setScroll={setScroll} />
      </NavbarContainer>
      <HeaderSlider />
    </Container>
  )
}

Header.propTypes = {
  setScroll: PropTypes.func,
}

export default Header

const Container = styled.div`
  position: relative;
`

const NavbarContainer = styled.div`
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 100vw;
`
