import { IconButton } from '@mui/material'
import styled from 'styled-components'
import { SocialIcon } from 'react-social-icons'

function FooterBar({ style }) {
  return (
    <Container style={style}>
      <Content>
        <FlexContainer>
          <Logo>
            <div className="logo">Cheongdam</div>
            <div className="sub-logo">Architects & Engineers</div>
          </Logo>
          <ButtonContainer>
            <SocialIcon url="https://www.facebook.com/" bgColor="white" />
            <SocialIcon url="https://www.instagram.com/" bgColor="white" />
            <SocialIcon url="https://www.youtube.com/" bgColor="white" />
          </ButtonContainer>
        </FlexContainer>
        <Info>
          충청북도 음성군 맹동면 대하2가길 31, 6층 601호(도시메디컬타워),
          청담건축사사무소
          <br />
          <span className="bold">대표/건축사</span> 주홍민&nbsp;&nbsp;
          <span className="bold">사업자등록번호</span> 370-31-00617&nbsp;&nbsp;
          <span className="bold">TEL</span> 043-882-7901&nbsp;&nbsp;
          <span className="bold">FAX</span> 043-882-7902
        </Info>
      </Content>
      <Copyright>
        Copyright 2022. CHEONGDAM ARCHITECTS & ENGINEERS All rights reserved.
      </Copyright>
    </Container>
  )
}

const Container = styled.div`
  padding: max(2.5rem, 48px) 12rem;

  background: var(--primary);
  color: white;

  @media (max-width: 800px) {
    padding: 48px 36px;
  }
`

const Content = styled.div`
  padding-bottom: 30px;
`

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: max(30px, 1.65rem);

  @media (max-width: 450px) {
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }
`

const Logo = styled.div`
  text-align: center;
  .logo {
    font-family: hanMaum;
    font-size: max(36px, 2rem);
    line-height: max(48px, 2.5rem);
  }

  .sub-logo {
    font-family: score;
    font-weight: 200;
    font-size: max(17px, 0.8rem);
  }

  @media (max-width: 650px) {
    .logo {
      font-size: 28px;
      line-height: 36px;
    }
    .sub-logo {
      font-size: 12px;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  a:hover .social-svg-mask {
    fill: var(--primary-light-100) !important;
  }
`

const Info = styled.div`
  .bold {
    font-weight: 500;
  }

  font-family: score;
  font-weight: 200;

  font-size: max(14px, 0.85rem);
  line-height: max(28px, 1.8rem);

  @media (max-width: 650px) {
    font-size: 12px;
  }
`

const Copyright = styled.div`
  font-size: max(18px, 0.8rem);
  padding-top: max(28px, 1.5rem);
  border-top: 1px solid white;

  font-family: hanMaum;

  @media (max-width: 650px) {
    font-size: 14px;
    line-height: 24px;
  }
`

export default FooterBar
