import styled from 'styled-components'
import Body from '../components/Overview/Body'

import React from 'react'

import { Scrollbar } from 'smooth-scrollbar-react'
import setMetaTags from '../utils/setMetaTags'
function Overview() {
  const [enableScroll, setScroll] = React.useState(true)

  React.useEffect(() => {
    const title = '회사소개 - 청담 건축사사무소'
    setMetaTags({
      title: title,
      description: '청담 건축사사무소의 소개 페이지입니다.',
    })

    const titleElement = document.getElementsByTagName('title')[0]
    titleElement.innerHTML = title

    return () => {
      setMetaTags({})
      titleElement.innerHTML = '청담 건축사사무소'
    }
  }, [])
  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: 'glow',
        },
      }}
    >
      <Container>
        <Body enableScroll={enableScroll} setScroll={setScroll} />
      </Container>
    </Scrollbar>
  )
}

const Container = styled.div`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100vh;
  position: fixed;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

export default Overview
