import styled from 'styled-components'
import PropTypes from 'prop-types'
import NavItem from '../NavItem/NavItem'

function MenuItem({ isNavHover, isItemHover, onMouseEnter, children }) {
  return (
    <NavItem target="" isNavHover={isNavHover}>
      <Container
        style={isItemHover ? { fontWeight: 500 } : { fontWeight: 200 }}
        onMouseEnter={onMouseEnter}
      >
        <MenuText>{children}</MenuText>
        {isItemHover ? <Indicator /> : null}
      </Container>
    </NavItem>
  )
}

MenuItem.propTypes = {
  isNavHover: PropTypes.bool.isRequired,
}

const Container = styled.div`
  position: relative;
  display: table;
  height: 100%;
  font-family: score;
  font-size: max(min(1rem, 21px), 16px);
  text-align: center;
`

const MenuText = styled.span`
  padding: 0 20px;
  display: table-cell;
  vertical-align: middle;
`

const Indicator = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3px;
  background: var(--primary);

  animation: scale-up-hor-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  @keyframes scale-up-hor-center {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
`

export default MenuItem
