import styled from 'styled-components'
import { Label } from './Body'
import { useInView } from 'react-intersection-observer'

function SectionFive() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <Container ref={ref}>
      <Content style={inView ? { opacity: '1' } : { opacity: '0' }}>
        <Title className={inView ? 'fade-in-left' : ''}>
          <Label color="var(--second)">what will we do</Label>
          <span>청담은 고객만족과 함께 </span>
          <span>더욱 성장합니다.</span>
        </Title>
        <Desc
          className={inView ? 'fade-in-left' : ''}
          style={{ animationDelay: '0.5s' }}
        >
          <span>
            청담건축사사무소는 위 경영이념을 철저히 준수하고, 고객만족과
          </span>
          <span>신뢰를 발판으로 삼아 글로벌 기업으로 성장합니다.</span>
        </Desc>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100vw;
  flex: 1;
  font-family: score;
  background-image: url('images/world.png');
  background-size: cover;
  background-position: center 45%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  width: 100%;
  height: 100%;

  padding: max(5rem, 60px) 7rem;
  box-sizing: border-box;
`

const Title = styled.div`
  font-weight: 600;
  font-size: max(3rem, 42px);
  line-height: max(4.2rem, 56px);
  color: var(--primary);
  span {
    display: block;
  }

  @media (max-width: 550px) {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;

    span {
      display: inline;
    }
  }
`

const Desc = styled.div`
  font-family: score;
  font-size: max(1.15rem, 18px);
  line-height: max(1.8rem, 28px);
  font-weight: 400;
  span {
    display: block;
  }

  @media (max-width: 720px) {
    span {
      display: inline;
    }
  }
`

export default SectionFive
