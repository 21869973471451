import styled from 'styled-components'
import Business from './Business/Business.js'
import Welcome from './Welcome/Welcome'

function AboutCheongdam() {
  return (
    <Container>
      <Welcome />
      <Business />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  @media (max-height: 800px) {
    height: auto;
  }
`

export default AboutCheongdam
