import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useInView } from 'react-intersection-observer'
import { Label } from './Body'
import { useEffect } from 'react'

function GradiantIcon({ children }) {
  return <GradiantContainer>{children}</GradiantContainer>
}

const GradiantContainer = styled.span`
  .material-symbols-outlined {
    font-size: max(6rem, 64px);

    font-family: 'Material Symbols Outlined';
    background: rgb(0, 224, 240);
    background: linear-gradient(
      180deg,
      rgba(0, 224, 240, 1) 0%,
      rgba(0, 122, 240, 1) 100%
    );

    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  @media (max-width: 450px) {
    .material-symbols-outlined {
      font-size: 48px;
    }
  }
`

function IconItem({ iconName, title, className, style, children }) {
  return (
    <IconItemContainer className={className} style={style}>
      <GradiantIcon>
        <span className="material-symbols-outlined">{iconName}</span>
      </GradiantIcon>
      <div className="title">{title}</div>
      {children}
    </IconItemContainer>
  )
}

IconItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const IconItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: max(2rem, 10px);

  text-align: center;
  font-family: score;

  font-size: max(1.2rem, 18px);
  line-height: max(2rem, 28px);

  .title {
    font-weight: 600;
    font-size: max(1.7rem, 24px);
  }

  @media (max-width: 900px) {
    .title {
      font-size: 16px;
    }

    font-size: 14px;
    gap: 5px;
  }
`

function SectionThree() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  useEffect(() => {
    console.log(inView)
  }, [inView])
  return (
    <Container ref={ref}>
      <Content style={inView ? { opacity: 1 } : { opacity: 0 }}>
        <Title className={inView ? 'fade-in-bottom' : null}>
          <Label color="var(--second)">what we think</Label>
          <span>
            청담은 <span className="underline">3가지 핵심가치</span>를{' '}
          </span>
          <span>실천합니다.</span>
        </Title>
        <IconContainer>
          <IconItem
            iconName="handshake"
            title="People"
            className={inView ? 'fade-in-bottom' : null}
            style={inView ? { animationDelay: '0.5s' } : null}
          >
            이윤보다 사람을 남기는
            <br />
            지속가능한 계약을 지향합니다.
          </IconItem>
          <IconItem
            iconName="sentiment_satisfied"
            title="Satisfaction"
            className={inView ? 'fade-in-bottom' : null}
            style={inView ? { animationDelay: '1s' } : null}
          >
            무엇보다 고객의 만족을
            <br />
            최우선으로 생각합니다.
          </IconItem>
          <IconItem
            iconName="public"
            title="Sustainability"
            className={inView ? 'fade-in-bottom' : null}
            style={inView ? { animationDelay: '1.5s' } : null}
          >
            사람, 자연과 상호공존 가능한
            <br />
            지속가능한 건축을 지향합니다.
          </IconItem>
        </IconContainer>
      </Content>
      <Overlay />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100vh;
  background: white;

  font-family: score;

  @media (max-height: 800px) {
    height: auto;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: max(10rem, 48px);

  height: 100%;

  box-sizing: border-box;
  padding: max(5rem, 60px) 7rem;
  z-index: 3;

  // @media (max-width: 900px) {
  //   align-items: center;
  // }
`

const Title = styled.div`
  font-weight: 600;
  font-size: max(3rem, 42px);
  line-height: max(4.2rem, 56px);

  color: var(--primary);
  .underline {
    display: inline-block;
    line-height: max(0.5rem, 12px);
    border-bottom: max(2.3rem, 21px) solid var(--second);
  }

  span:not(.underline) {
    display: block;
  }

  @media (max-width: 550px) {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;

    span:not(.underline) {
      display: inline;
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 900px) {
    width: 100%;
    justify-content: space-between;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    gap: 50px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('images/diamond_pattern.jpg');
  background-size: cover;
  opacity: 0.7;
  z-index: 2;
`

export default SectionThree
