import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from '../NavItem/Logo'
import MenuButton from '../NavItem/MenuButton'
import CloseIcon from '@mui/icons-material/Close'
import OverviewItem from './OverviewItem/OverviewItem'
import React from 'react'

function MenuOverview({ hideOverview, menuInfo }) {
  return (
    <Container className="fade-in-3">
      <ControlContainer>
        <Logo isNavHover={true} />
        <MenuButton isNavHover={true} onClick={hideOverview}>
          <CloseIcon />
        </MenuButton>
      </ControlContainer>
      <MenuContainer>
        {menuInfo.map((menu, index) => (
          <OverviewItem key={index} title={menu.title} submenu={menu.submenu} />
        ))}
      </MenuContainer>
      <BackgroundImage
        src={process.env.PUBLIC_URL + '/images/modal_background.jpg'}
      />
    </Container>
  )
}

MenuOverview.propTypes = {
  hideOverview: PropTypes.func.isRequired,
  menuInfo: PropTypes.array.isRequired,
}

export default MenuOverview

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: white;
`

const ControlContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 100px;
  padding: 0 60px;
  box-sizing: border-box;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  box-sizing: border-box;
  padding: 0 60px;
  font-size: 8vw;
`

const BackgroundImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: auto;
  overflow: hidden;
  height: 100%;
  z-index: -1;
`
