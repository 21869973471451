import styled from 'styled-components'
import PropTypes from 'prop-types'
import StyledLink from '../StyledLink'

function Post({
  index,
  no,
  type,
  title,
  created,
  highlighted = false,
  bold = false,
}) {
  return (
    <div style={highlighted ? { background: 'var(--light-200)' } : null}>
      <StyledLink to={`/about/board/${index}`}>
        <Container style={bold ? { fontWeight: 500 } : null}>
          <div>{no}</div>
          <div>{type}</div>
          <div className="title">{title}</div>
          <div>{created}</div>
        </Container>
      </StyledLink>
    </div>
  )
}

Post.propTypes = {
  index: PropTypes.number.isRequired,
  no: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  bold: PropTypes.bool,
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 4fr 2fr;

  border-bottom: 2px solid var(--light-300);
  color: black;

  font-family: score;
  font-size: max(1rem, 19px);

  div {
    text-align: center;
    margin: max(1rem, 18px);
  }

  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
    div {
      margin: 16px;
    }
  }

  @media (max-width: 700px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    div:nth-child(1) {
      order: 1;
    }
    div:nth-child(2) {
      order: 2;
    }
    div:nth-child(3) {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      order: 4;
    }
    div:nth-child(4) {
      order: 3;
    }
  }
`

export default Post
