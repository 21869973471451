import NavItem from './NavItem'
import PropTyes from 'prop-types'
import styled from 'styled-components'
function Logo({ isNavHover }) {
  return (
    <Container>
      <NavItem target="/" isNavHover={isNavHover}>
        청담 <span className="small">건축사사무소</span>
      </NavItem>
    </Container>
  )
}

Logo.propTypes = {
  isNavHover: PropTyes.bool.isRequired,
}

const Container = styled.div`
  font-family: hanMaum;
  font-size: max(3rem, 36px);
  vertical-align: middle;
  white-space: nowrap;

  .small {
    font-size: max(0.7em, 16px);
    line-height: max(2em, 32px);
    vertical-align: middle;
  }
`

export default Logo
