import PropTypes from 'prop-types'
import styled from 'styled-components'
import StyledLink from '../../../StyledLink'
import React from 'react'
import FlipArrow from './FlipArrow'
import OverviewSubItem from './OverviewSubItem'

function OverviewItem({ title, submenu }) {
  const isSubMenuExist = submenu.length > 0 ? true : false
  const [isSubMenuOpen, setSubMenuOpen] = React.useState(false)

  const flipSubMenuOpen = () => {
    setSubMenuOpen(prev => !prev)
  }
  return (
    <Container>
      <Title>
        <StyledLink onClick={isSubMenuExist ? flipSubMenuOpen : null}>
          {title}
        </StyledLink>
        {isSubMenuExist ? <FlipArrow isArrowFlip={isSubMenuOpen} /> : null}
      </Title>
      <OverviewSubItem submenu={submenu} isSubMenuOpen={isSubMenuOpen} />
    </Container>
  )
}

OverviewItem.propTypes = {
  title: PropTypes.string.isRequired,
  submenu: PropTypes.array.isRequired,
}

export default OverviewItem

const Container = styled.div`
  margin-bottom: 50px;
`

const Title = styled.div`
  font-family: score;
  font-weight: 400;
  @media screen and (min-width: 500px) {
    font-size: 36px;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.3em;
  }

  a {
    color: var(--primary);
  }
`
