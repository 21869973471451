import styled from 'styled-components'
import { Label } from './Body'
import { useInView } from 'react-intersection-observer'

function SectionFour() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <Container ref={ref}>
      <Overlay className={inView ? 'expand-both' : null} />
      <Video autoPlay playsinline loop muted>
        <source
          src={process.env.PUBLIC_URL + '/videos/wind_power.mp4'}
          type="video/mp4"
        />
      </Video>
      <TextContainer style={inView ? { opacity: '1' } : { opacity: '0' }}>
        <div
          className={inView ? 'fade-in-bottom title' : 'title'}
          style={{ animationDelay: '1.5s' }}
        >
          <Label color="white">why we move</Label>
          <span>We move constantly thinking about </span>
          <span>the environment for a better future.</span>
        </div>
        <div
          className={inView ? 'fade-in-bottom desc' : 'desc'}
          style={{ animationDelay: '2s' }}
        >
          <span>
            청담 건축사사무소는 우리 아이들이 살아갈 더 나은 미래를 위해 ESG
            책임경영을 실천하며,
          </span>
          <span>지속가능경영을 확장하기 위해 최선을 다합니다.</span>
        </div>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding: max(10rem, 60px) 7rem;
  box-sizing: border-box;

  /* 전체화면 버튼 */
  video::-webkit-media-controls-fullscreen-button,
  video::-webkit-media-controls-play-button,
  video::-webkit-media-controls-timeline,
  video::-webkit-media-controls-current-time-display,
  video::-webkit-media-controls-time-remaining-display,
  video::-webkit-media-controls-mute-button,
  video::-webkit-media-controls-volume-slider {
    display: none !important;
  }
`

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
`

const Overlay = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);

  width: 0%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
`

const TextContainer = styled.div`
  position: relative;
  color: white;
  z-index: 3;

  .title {
    font-family: hanMaum;
    font-size: max(3rem, 38px);
    line-height: max(4rem, 46px);
    color: white;
    margin-bottom: max(2rem, 30px);
  }

  .title span {
    display: block;
  }

  .desc {
    font-family: score;
    font-weight: 200;
    font-size: max(1.1rem, 16px);
    line-height: max(2rem, 28px);
  }

  .desc span {
    display: block;
  }

  @media (max-width: 550px) {
    .desc span {
      display: inline;
    }
  }

  @media (max-width: 550px) {
    .title {
      font-size: 32px;
      line-height: 42px;
    }
  }

  @media (max-width: 550px) {
    .title span {
      display: inline;
    }
  }
`

export default SectionFour
