import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Select, FormControl, MenuItem, InputLabel } from '@mui/material'

function SelectType({ type, setType }) {
  const handleChange = event => {
    setType(event.target.value)
  }

  return (
    <StyledControl variant="standard" sx={{ m: 1, minWidth: 160 }}>
      <InputLabel id="demo-simple-select-standard-label">보기</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={type}
        onChange={handleChange}
        defaultValue={type}
      >
        <StyledMenuItem value="all">전체</StyledMenuItem>
        <StyledMenuItem value="bid">입찰</StyledMenuItem>
        <StyledMenuItem value="award">수상</StyledMenuItem>
        <StyledMenuItem value="recruitment">채용</StyledMenuItem>
        <StyledMenuItem value="news">뉴스</StyledMenuItem>
      </Select>
    </StyledControl>
  )
}

SelectType.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
}

const StyledControl = styled(FormControl)`
  * {
    font-family: score !important;
    font-size: max(1rem, 18px) !important;
  }

  margin: 0 !important;
  color: black;
`

const StyledMenuItem = styled(MenuItem)`
  font-size: max(1rem, 18px) !important;
`

export default SelectType
