const setMetaTags = ({
  title = '청담 건축사사무소',
  description = '청담 건축사사무소 홈페이지에 오신 것을 환영합니다.',
  imageUrl = 'favicon.ico',
}) => {
  //set title
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute('content', `${title}`)

  //set description
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute('content', description)

  //set images
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute('content', imageUrl)

  //set url
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute('content', window.location.href)
}

export default setMetaTags
