import styled from 'styled-components'
import FooterBar from '../FooterBar/FooterBar'
import Navbar from '../Navbar/Navbar'

function Body({ enableScroll, setScroll }) {
  return (
    <Container>
      <FlexContainer>
        <Navbar fixMenuIdx={1} fixSubmenuIdx={1} setScroll={setScroll} />
        <ContentContainer style={enableScroll ? null : { display: 'none' }}>
          <Image />
          <Content>
            <TextContainer>
              <Title className="fade-in-bottom">
                청담을 찾아주셔서 감사합니다.
              </Title>
              <Description
                className="fade-in-bottom"
                style={{ animationDelay: '0.5s' }}
              >
                <span>
                  청담건축사사무소는 건축전문 회사로서의 사명을 가지고{' '}
                </span>
                <span>
                  힘차게 도약하고 있으며, 전문적인 건축 설계와 환경을 생각하는{' '}
                </span>
                <span>
                  ESG 경영을 지향하고 있습니다.
                  <br />
                </span>
                <br />

                <span>
                  또한 저희 임직원 일동은 건축사업은 사람과 직접 연결되는{' '}
                </span>
                <span>
                  중요한 사업이라는 일념으로, 무엇보다 안전을 생각하는
                </span>
                <span>
                  건축을 실현하고자 노력하고 있습니다.
                  <br />
                </span>
                <br />

                <span>
                  앞으로도 저희는 전문적인 기술과 고객만족을 바탕으로{' '}
                </span>
                <span>고객을 항상 생각하는 든든한 파트너가 되겠습니다.</span>
              </Description>
              <Sign className="fade-in-bottom" style={{ animationDelay: '1s' }}>
                <div className="info-container">
                  <div className="company">청담건축사사무소</div>
                  <div>
                    <span className="position">대표/건축사 </span>
                    <span className="name">주홍민</span>
                  </div>
                </div>
              </Sign>
            </TextContainer>
          </Content>
        </ContentContainer>
      </FlexContainer>
      <FooterBar style={enableScroll ? null : { display: 'none' }} />
    </Container>
  )
}

const Container = styled.div`
  width: 100vw;
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 100vh;
  @media (max-height: 800px) {
    height: auto;
  }
`

const ContentContainer = styled.div`
  position: relative;
  width: 100%;

  @media (min-height: 800px) {
    flex: 1;
  }
  @media (max-height: 800px) {
    padding: 40px 0;
  }
`

const Image = styled.div`
  position: absolute;
  right: 0;
  width: 50rem;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.9) 75%,
      rgba(255, 255, 255, 0.95) 85%,
      rgba(255, 255, 255, 1) 100%
    ),
    url('images/overview_one.jpg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  opacity: 1;

  @media (max-width: 1500px) {
    width: 45rem;
  }

  @media (max-width: 1050px) {
    width: 40rem;
  }
  @media (max-width: 950px) {
    display: none;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  margin: 0 15rem;

  @media (max-width: 1300px) {
    margin: 0 5rem;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: max(5rem, 40px);
  height: 100%;

  font-size: max(1rem, 18px);
  line-height: max(1.8rem, 28px);

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const Title = styled.div`
  font-family: hanMaum;
  font-size: max(2.5rem, 42px);
  line-height: 48px;
  color: var(--primary);

  word-break: keep-all;

  @media (max-width: 600px) {
    font-size: 36px;
  }
`

const Description = styled.div`
  font-family: score;

  color: black;

  span {
    display: block;
  }

  @media (max-width: 600px) {
    span {
      display: inline;
    }
  }
`

const Sign = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 1.2rem;

  font-family: score;
  color: var(--primary);

  .info-conatiner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .company,
  .position {
    font-size: max(1rem, 16px);
    text-align: right;
  }

  .name {
    font-weight: 600;
    font-size: max(1.1rem, 21px);
  }

  .sign {
    flex: 1;
    height: 130%;
    min-height: 60px;
    background-image: url('images/sign.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
`

const Person = styled.div`
  flex: 1;
  width: auto;
  height: 85%;
  background-image: url('images/ceo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;

  @media (max-width: 920px) {
    display: none;
  }
`

export default Body
