import styled from 'styled-components'
import Slider from 'react-slick'
import CustomArrow from '../../../../CustomArrow'
import { IconButton } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import PropTypes from 'prop-types'
import ResponsiveText from '../../../../ResponsiveText'

function SlideImage({ image }) {
  return (
    <div
      style={{
        position: 'absolute',
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: '100%',
        height: '100%',
        zIndex: '-1',
      }}
    ></div>
  )
}

function Slide({ image, title, children }) {
  return (
    <SlideContainer>
      <BusinessOverlay />
      <SlideImage image={image} />
      <BusinessInfo className="not-full-height">
        <div className="title">{title}</div>
        {children}
      </BusinessInfo>
    </SlideContainer>
  )
}

Slide.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const SlideContainer = styled.div`
  position: relative;
  object-fit: cover;
`

const BusinessOverlay = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
`

const BusinessInfo = styled.div`
  position: absolute;
  height: auto;

  box-sizing: border-box;

  bottom: 0;
  padding: 2rem;

  z-index: 3;
  color: white;
  font-family: score;
  font-weight: 100;
  font-size: max(1rem, 14px);
  line-height: max(1.2rem, 18px);
  .title {
    font-weight: 500;
    font-size: max(1.6rem, 20px);
    line-height: max(2rem, 32px);
  }
`

function BusinessSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: (
      <CustomArrow
        name="next"
        icon={
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        }
      />
    ),
  }
  return (
    <StyledSlider {...settings}>
      <Slide
        image={process.env.PUBLIC_URL + '/images/business_plan.jpg'}
        title="건축설계"
      >
        <ResponsiveText>사업분석, 계획, 실시설계</ResponsiveText>
        <ResponsiveText>전반으로 이루어지는 최적의</ResponsiveText>
        <ResponsiveText>사업솔루션을 제공합니다.</ResponsiveText>
      </Slide>
      <Slide
        image={process.env.PUBLIC_URL + '/images/business_structure.jpg'}
        title="구조설계"
      >
        <ResponsiveText>구조기술사와 협업하여</ResponsiveText>
        <ResponsiveText>최적안의 구조설계를 제공합니다.</ResponsiveText>
      </Slide>
      <Slide
        image={process.env.PUBLIC_URL + '/images/business_contract.jpg'}
        title="토목설계"
      >
        <ResponsiveText>토목설계 전문가와 협업해</ResponsiveText>
        <ResponsiveText>최적안의 토목설계를 제공합니다.</ResponsiveText>
      </Slide>
    </StyledSlider>
  )
}

const StyledSlider = styled(Slider)`
  height: 100%;

  div:not(.not-full-height) {
    height: 100%;
  }

  .slick-slide {
    width: 4%;
    margin-right: 20px;
  }

  .slick-prev {
    display: none !important;
  }

  .next {
    width: max(4rem, 64px) !important;
    height: max(4rem, 64px) !important;
    border-radius: 9999%;
    background: rgba(0, 0, 0, 0.6);
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
  }

  .next button {
    width: 100%;
    height: 100%;
  }

  .next svg {
    color: white;
    font-size: max(1.5rem, 24px);
  }

  @media (max-width: 650px) {
    .slick-slide {
      width: 5%;
    }
  }
`

export default BusinessSlider
