import styled from 'styled-components'
import FooterBar from '../../../FooterBar/FooterBar'
import SectionInfo from '../SectionInfo'
import ViewMoreBtn from '../ViewMoreBtn'
import StyledLink from '../../../StyledLink'
import PropTypes from 'prop-types'
import React from 'react'

import { useInView } from 'react-intersection-observer'
import axios from 'axios'
import { POST_TYPE_KOR_DICT } from '../../../../utils/dictionarys'
import api from '../../../../utils/api'

function PostItem({ date, id, type, title, content, className, style }) {
  var [year, month, days] = date.split('-')
  return (
    <Post className={className} style={style}>
      <StyledLink to={`/about/board/${id}`}>
        <div className="date">
          <span className="days">{days}</span>
          {year}.{month}
        </div>
        <div className="post-info">
          <div className="title">
            [{POST_TYPE_KOR_DICT[type]}] {title}
          </div>
          <div className="content">
            <p>{content}</p>
          </div>
        </div>
      </StyledLink>
    </Post>
  )
}

PostItem.propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const Post = styled.div`
  width: 100%;
  position: relative;
  a {
    position: relatvie;
    display: flex;
    gap: 40px;
    padding: max(1.5rem, 27px) max(1rem, 18px);
    width: 90%;
    margin: 0 auto;
    font-family: score;
    color: black;
    border-bottom: 1px solid var(--light-600);
  }

  .date {
    height: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-size: max(0.8rem, 14px);
  }

  .date .days {
    font-size: max(2rem, 36px);
    line-height: max(2.5rem, 48px);
    font-weight: 500;
    text-align: center;
    color: var(--primary);
  }

  .post-info {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .content {
    width: 100%;

    font-size: max(0.98rem, 18px);
    line-height: max(1.5rem, 28px);
  }

  .content p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    font-weight: 500;
    font-size: max(1rem, 18px);
    line-height: max(2rem, 36px);
  }

  @media (max-width: 1100px) {
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (max-width: 900px) {
    .content {
      font-size: 16px;
      line-height: 21px;
    }

    .title {
      margin-bottom: 5px;
    }
  }

  @media (max-width: 450px) {
    a {
      flex-direction: column;
      gap: 0;
      justify-content: space-between;
      height: 90%;
    }

    .date {
      flex-direction: row;
    }

    .date .days {
      font-size: 24px;
      margin-right: 8px;
    }

    .post-info {
      margin-bottom: 10px;
    }

    .title {
      font-size: 18px;
    }
  }
`

function NoticeAndNews() {
  const [postData, setPostData] = React.useState([])

  const fetchPost = React.useCallback(async () => {
    const res = await api.get('/recent-post')
    setPostData(res.data)
  }, [])

  React.useEffect(() => {
    fetchPost()
  }, [])

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <Container ref={ref}>
      <ContentContainer style={inView ? { opacity: '1' } : { opacity: '0' }}>
        <Content>
          <InfoContainer className={inView ? 'fade-in-bottom-1-2' : null}>
            <SectionInfo title="NOTICE &NEWS">
              청담 건축사사무소의 소식을 빠르게 만나보세요.
            </SectionInfo>
            <ViewMoreBtnContainer>
              <ViewMoreBtn target="/about/board" />
            </ViewMoreBtnContainer>
          </InfoContainer>
          <PostContainer>
            {postData.map((post, index) => (
              <PostItem
                className={inView ? 'fade-in-bottom-1-2' : null}
                style={
                  inView ? { animationDelay: `${0.3 * (index + 1)}s` } : null
                }
                key={index}
                id={post.id}
                date={post.created_date}
                type={post.type}
                title={post.title}
                content={post.content_text}
              />
            ))}
          </PostContainer>
        </Content>
      </ContentContainer>
      <FooterBar />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media (max-width: 450px) {
    height: auto;
  }
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-height: 800px) {
    flex: 1;
  }

  @media (max-height: 800px), (max-width: 450px) {
    height: auto;
    padding: 40px 0;
  }
`

const Content = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  gap: 8rem;

  @media (max-width: 1300px) {
    width: 90%;
    gap: 0;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: max(11.6rem, 240px);
  word-break: keep-all;

  @media (max-width: 900px) {
    width: 100%;
  }
`

const ViewMoreBtnContainer = styled.div`
  margin-top: max(3rem, 59px);
  @media (max-width: 900px) {
    margin-top: 20px;
  }
`

const PostContainer = styled.div`
  flex: 1;
  border-top: 3px solid var(--light-600);
  border-bottom: 3px solid var(--light-600);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  a {
    height: auto;
  }
`

export default NoticeAndNews
