import styled from 'styled-components'
import { IconButton } from '@mui/material'
import PropTypes from 'prop-types'

function MenuButton({ isNavHover, onClick, children }) {
  return (
    <Container onClick={() => onClick()}>
      <IconButton color={isNavHover ? 'primary' : 'white'}>
        {children}
      </IconButton>
    </Container>
  )
}

MenuButton.propTypes = {
  isNavHover: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const Container = styled.div`
  margin-left: 8px;
  display: flex;

  .button-selected {
    color: white;
  }

  svg {
    font-size: 32px;
  }
`

export default MenuButton
