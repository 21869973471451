import { HashRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './routes/Home'
import { Reset } from 'styled-reset'
import React from 'react'
import styled from 'styled-components'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Overview from './routes/Overview'
import CeoMessage from './routes/CeoMessage'
import Board from './routes/Board'
import BoardDetail from './routes/BoardDetail'
import Works from './routes/Works'
import WorkDetail from './routes/WorkDetail'
import Directions from './routes/Directions'
import Inquiry from './routes/Inquiry'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1e3c89',
    },
    white: {
      main: '#ffffff',
    },
    black: {
      main: '#000000',
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Body>
        <Reset />
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about/overview" element={<Overview />} />
            <Route path="/about/greeting" element={<CeoMessage />} />
            <Route path="/about/board" element={<Board />} />
            <Route path="/about/board/:id" element={<BoardDetail />} />
            <Route path="/works" element={<Works />} />
            <Route path="/works/:id" element={<WorkDetail />} />
            <Route path="/contact/directions" element={<Directions />} />
            <Route path="/contact/inquiry" element={<Inquiry />} />
          </Routes>
        </Router>
      </Body>
    </ThemeProvider>
  )
}
export default App

export const Body = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  /* Fonts ------------------------------ */
  @font-face {
    font-family: 'hanMaum';
    src: url('fonts/KBIZ한마음명조 M.ttf');
  }

  @font-face {
    font-family: 'twaySky';
    src: url('fonts/TwaySky.ttf');
  }

  @font-face {
    font-family: 'nanumPen';
    src: url('fonts/NanumPen.ttf');
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream1.otf');
    font-weight: 100;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream2.otf');
    font-weight: 200;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream3.otf');
    font-weight: 300;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream4.otf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream5.otf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream6.otf');
    font-weight: 600;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream7.otf');
    font-weight: 700;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream8.otf');
    font-weight: 800;
  }

  @font-face {
    font-family: 'score';
    src: url('fonts/SCDream9.otf');
    font-weight: 900;
  }

  // icons -----------------------------------
  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 100 700;
    src: url('fonts/MaterialSymbolOutlined.woff2') format('woff2');
  }
`
