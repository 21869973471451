import styled from 'styled-components'
import StyledLink from '../../StyledLink'
import PropTypes from 'prop-types'

function MenuSubItem({ target, isFocused = false, children }) {
  if (isFocused) {
    return (
      <div style={{ display: 'table', fontWeight: '600' }}>
        <Container>{children}</Container>
      </div>
    )
  }
  return (
    <StyledLink to={target} style={{ display: 'table', fontWeight: '400' }}>
      <Container>{children}</Container>
    </StyledLink>
  )
}

MenuSubItem.propTypes = {
  target: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
}

const Container = styled.div`
  width: 200px;
  height: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  font-family: score;
  font-size: max(min(1em, 21px), 16px);
  color: var(--primary);
`

export default MenuSubItem
