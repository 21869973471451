function CustomArrow({ _, style, onClick, name, icon }) {
  return (
    <div
      className={name}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      {icon}
    </div>
  )
}

export default CustomArrow
