import styled from 'styled-components'
import SectionInfo from '../SectionInfo'
import ResponsiveText from '../../../ResponsiveText'
import LogoOverlay from '../LogoOverlay'
import WorkItem from '../../../WorkItem'
import ViewMoreBtn from '../ViewMoreBtn'

import { useInView } from 'react-intersection-observer'
import React from 'react'
import axios from 'axios'
import api from '../../../../utils/api'

// const workData = [
//   {
//     index: 0,
//     name: '광주 오페라하우스',
//     addr: '광주광역시 남구 충정새암로 61-8',
//     portrait: `images/building_1.jpg`,
//     images: [
//       `images/building_1.jpg`,
//       `images/building_1.jpg`,
//       `images/building_1.jpg`,
//     ],
//     region: 'Gwanju',
//     type: 'education/culture',
//     design_date: '2021-09-10',
//     construction_date: '2022-03-27',
//     isTopProject: 'true',
//   },
//   {
//     index: 1,
//     name: '대구 Cafe MINICLIP',
//     addr: '대구광역시 북구 호암로 15',
//     portrait: `images/building_2.jpg`,
//     images: [
//       `images/building_2.jpg`,
//       `images/building_2.jpg`,
//       `images/building_2.jpg`,
//     ],
//     region: 'Daegu',
//     type: 'others',
//     design_date: '2021-06-22',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 2,
//     name: '음성 혁신광학연구소',
//     addr: '충북 음성군 혁신산업단지 14-2',
//     portrait: `images/building_3.jpg`,
//     images: [
//       `images/building_3.jpg`,
//       `images/building_3.jpg`,
//       `images/building_3.jpg`,
//     ],
//     region: 'Chungcheong-do',
//     type: 'medical/research',
//     design_date: '2020-08-30',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 3,
//     name: '부산 오페라하우스',
//     addr: '부산광역시 중구 문화예술로 3',
//     portrait: `images/building_4.jpg`,
//     images: [
//       `images/building_4.jpg`,
//       `images/building_4.jpg`,
//       `images/building_4.jpg`,
//     ],
//     region: 'Busan',
//     type: 'education/culture',
//     design_date: '2020-02-08',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 4,
//     name: '청주 리버사이드 2차',
//     addr: '충북 청주시 흥덕구 대율로 341',
//     portrait: `images/building_5.jpg`,
//     images: [
//       `images/building_5.jpg`,
//       `images/building_5.jpg`,
//       `images/building_5.jpg`,
//     ],
//     region: 'Chungcheong-do',
//     type: 'skyscraper/residence',
//     design_date: '2019-11-17',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 5,
//     name: '서울 국립현대미술관',
//     addr: '서울특별시 중구 삼청로 30',
//     portrait: `images/building_6.jpg`,
//     images: [
//       `images/building_6.jpg`,
//       `images/building_6.jpg`,
//       `images/building_6.jpg`,
//     ],
//     region: 'Seoul',
//     type: 'education/culture',
//     design_date: '2019-10-16',
//     construction_date: '2022-03-27',
//     isTopProject: 'true',
//   },
// ]

function Works() {
  const [workData, setWorkData] = React.useState([])

  const fetchWork = React.useCallback(async () => {
    const res = await api.get('/recent-work')
    setWorkData(res.data.slice(0, -1))
  }, [])

  React.useEffect(() => {
    fetchWork()
  }, [])

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  const fillEmptyWork = () => {
    const dummyList = []

    for (let i = 0; i < 6 - workData.length; i++) {
      dummyList.push(
        <WorkItem
          key={i}
          isDummy={true}
          className={'item' + (inView ? ' fade-in-bottom-1-2' : '')}
          style={
            inView
              ? { animationDelay: `${0.3 * (workData.length + i + 1)}s` }
              : null
          }
          index={0}
          image="images/home_works_alt.jpg"
          title="청담 건축사사무소"
          desc="저희 사이트에 오신 것을 환영합니다."
        />
      )
    }
    return dummyList
  }

  return (
    <Container ref={ref}>
      <Content style={inView ? { opacity: '1' } : { opacity: '0' }}>
        <InfoContainer className={inView ? 'fade-in-bottom-1-2' : ''}>
          <SectionInfo title="WORKS">
            <ResponsiveText>
              청담 건축사사무소는 고객의 성공적인 사업완수와 고객만족을
              최우선으로 생각합니다.
            </ResponsiveText>
            <ResponsiveText>
              풍부한 경험과 전문적인 기술과 함께 임직원 일동이 최선을
              다하겠습니다.
            </ResponsiveText>
          </SectionInfo>
          <ViewMoreBtn />
        </InfoContainer>

        <CardContainer>
          {workData.map((work, index) => (
            <WorkItem
              className={'item' + (inView ? ' fade-in-bottom-1-2' : '')}
              style={
                inView ? { animationDelay: `${0.3 * (index + 1)}s` } : null
              }
              key={index}
              index={work.id}
              image={work.portrait}
              title={work.name}
              desc={work.address}
            />
          ))}
          {fillEmptyWork()}
        </CardContainer>
      </Content>
      <OverlayContainer>
        <LogoOverlay opacity={0.06}></LogoOverlay>
      </OverlayContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  position: relative;
  height: 100vh;
  @media (max-width: 1200px), (max-height: 800px) {
    height: auto;
  }
  padding: max(4rem, 40px) 0;
  background: var(--light-400);
  overflow: hidden;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  width: 90%;
  height: auto;
`

const OverlayContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
`

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  width: 100%;
`

const CardContainer = styled.div`
  display: grid;
  flex: 1;
  grid-gap: max(1.5rem, 20px);
  gap: max(1.5rem, 20px);
  z-index: 2;

  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: max(1rem, 15px);
    gap: max(1rem, 15px);
  }

  margin-top: 3vh;

  // items --------------------------------------
`
export default Works
