import Slider from 'react-slick'
import styled from 'styled-components'
import Slide from './Slide'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { IconButton } from '@mui/material'
import CustomArrow from '../../../CustomArrow'
import React from 'react'
import { REGION_KOR_DICT } from '../../../../utils/dictionarys'
import api from '../../../../utils/api'
import MainSlide from './MainSlide'

function HeaderSlider() {
  const [slideData, setSlideData] = React.useState([])

  const fetchWork = React.useCallback(async () => {
    const res = await api.get('/top-work')
    setSlideData(res.data)
  }, [])

  React.useEffect(() => {
    fetchWork()
  }, [])

  const settings = {
    nextArrow: (
      <CustomArrow
        name="next"
        icon={
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        }
      />
    ),
    prevArrow: (
      <CustomArrow
        name="prev"
        icon={
          <IconButton>
            <ArrowBackIosNewIcon />
          </IconButton>
        }
      />
    ),
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <StyledSlider {...settings}>
      <MainSlide
        name="청담 건축사사무소"
        addr="저희 사이트에 오신 것을 환영합니다."
        imgPath="images/header_slider_alt.jpg"
      />
      {slideData.map((slide, index) => (
        <Slide
          key={index}
          name={`${REGION_KOR_DICT[slide.region]} ${slide.name}`}
          addr={slide.address}
          imgPath={slide.portrait}
        />
      ))}
    </StyledSlider>
  )
}
export default HeaderSlider

export const StyledSlider = styled(Slider)`
  poisiton: relative;

  .prev,
  .next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 3;
    display: none;
  }

  .prev svg,
  .next svg {
    font-size: 64px;
    color: white;
  }

  .prev {
    @media screen and (min-width: 500px) {
      left: 80px;
    }

    @media screen and (max-width: 500px) {
      left: 50px;
    }
  }

  .next {
    @media screen and (min-width: 500px) {
      right: 80px;
    }

    @media screen and (max-width: 500px) {
      right: 50px;
    }
  }

  .slick-track {
    height: 100vh;
  }

  .slick-dots {
    @media screen and (min-width: 500px) {
      bottom: 50px;
      right: 80px;
    }

    @media screen and (max-width: 500px) {
      bottom: 50px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    width: auto;
  }

  .slick-dots li {
    margin: 0 7px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    background: white;
    color: transparent;
  }

  .slick-dots li button:before {
    width: 18px;
    height: 18px;
    font-size: 0px;
    line-height: 0px;
    opacity: 1;
    color: transparent;
    border-radius: 100%;
    border: 1px solid white;
  }
`
