import styled from 'styled-components'
import ResponsiveText from '../../../../ResponsiveText'

import { useInView } from 'react-intersection-observer'

function Welcome() {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <Container ref={ref}>
      <TextContainer style={inView ? { opacity: '1' } : { opacity: '0' }}>
        <Logo className={inView ? 'fade-in-bottom-1-2' : null}>청담,</Logo>
        <Description
          className={inView ? 'fade-in-bottom-1-2' : null}
          style={inView ? { animationDelay: '0.2s' } : null}
        >
          <ResponsiveText>
            청담 건축사사무소는 고객만족을 최우선으로 하는 사명을 갖고,{' '}
          </ResponsiveText>
          <ResponsiveText>
            각 분야의 전문가와 협업해 하나의 목표를 위해{' '}
          </ResponsiveText>
          <ResponsiveText>성공적인 프로젝트를 이루어 갑니다.</ResponsiveText>
        </Description>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  position: relative;
  font-family: hanMaum;
  @media (max-width: 650px), (max-height: 800px) {
    height: auto;
    padding: 40px;
  }
`

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8rem;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    gap: 0;
  }

  align-items: center;
`

const Logo = styled.div`
  font-size: max(4rem, 56px);

  color: var(--primary);
  white-space: nowrap;

  @media screen and (max-width: 950px) {
    font-size: 48px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 450px) {
    font-size: 42px;
  }
`

const Description = styled.div`
  text-align: center;

  font-size: max(1.2rem, 18px);
  line-height: max(2.1rem, 36px);

  @media screen and {
    white-space: nowrap;
  }

  @media screen and (max-width: 950px) {
    font-size: 16px;
    line-height: 36px;
  }
  @media screen and (max-width: 650px) {
    margin-left: 0;
    width: 90vw;
  }
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`

export default Welcome
