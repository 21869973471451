import styled from 'styled-components'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

import React, { useCallback } from 'react'
import Navbar from '../components/Navbar/Navbar'
import ScrollBody from '../components/ScrollBody'
import FooterBar from '../components/FooterBar/FooterBar'
import ViewButton from '../components/Works/ViewButton'
import FilterMenu from '../components/Works/FilterMenu'
import WorkItem from '../components/WorkItem'

import Pagination from 'react-js-pagination'
import { useMediaQuery } from 'react-responsive'
import axios from 'axios'
import setMetaTags from '../utils/setMetaTags'
import api from '../utils/api'

// const workData = [
//   {
//     index: 0,
//     name: '광주 오페라하우스',
//     addr: '광주광역시 남구 충정새암로 61-8',
//     portrait: `images/building_1.jpg`,
//     images: [
//       `images/building_1.jpg`,
//       `images/building_1.jpg`,
//       `images/building_1.jpg`,
//     ],
//     region: 'Gwanju',
//     type: 'education/culture',
//     design_date: '2021-09-10',
//     construction_date: '2022-03-27',
//     isTopProject: 'true',
//   },
//   {
//     index: 1,
//     name: '대구 Cafe MINICLIP',
//     addr: '대구광역시 북구 호암로 15',
//     portrait: `images/building_2.jpg`,
//     images: [
//       `images/building_2.jpg`,
//       `images/building_2.jpg`,
//       `images/building_2.jpg`,
//     ],
//     region: 'Daegu',
//     type: 'others',
//     design_date: '2021-06-22',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 2,
//     name: '음성 혁신광학연구소',
//     addr: '충북 음성군 혁신산업단지 14-2',
//     portrait: `images/building_3.jpg`,
//     images: [
//       `images/building_3.jpg`,
//       `images/building_3.jpg`,
//       `images/building_3.jpg`,
//     ],
//     region: 'Chungcheong-do',
//     type: 'medical/research',
//     design_date: '2020-08-30',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 3,
//     name: '부산 오페라하우스',
//     addr: '부산광역시 중구 문화예술로 3',
//     portrait: `images/building_4.jpg`,
//     images: [
//       `images/building_4.jpg`,
//       `images/building_4.jpg`,
//       `images/building_4.jpg`,
//     ],
//     region: 'Busan',
//     type: 'education/culture',
//     design_date: '2020-02-08',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 4,
//     name: '청주 리버사이드 2차',
//     addr: '충북 청주시 흥덕구 대율로 341',
//     portrait: `images/building_5.jpg`,
//     images: [
//       `images/building_5.jpg`,
//       `images/building_5.jpg`,
//       `images/building_5.jpg`,
//     ],
//     region: 'Chungcheong-do',
//     type: 'skyscraper/residence',
//     design_date: '2019-11-17',
//     construction_date: '2022-03-27',
//     isTopProject: 'false',
//   },
//   {
//     index: 5,
//     name: '서울 국립현대미술관',
//     addr: '서울특별시 중구 삼청로 30',
//     portrait: `images/building_6.jpg`,
//     images: [
//       `images/building_6.jpg`,
//       `images/building_6.jpg`,
//       `images/building_6.jpg`,
//     ],
//     region: 'Seoul',
//     type: 'education/culture',
//     design_date: '2019-10-16',
//     construction_date: '2022-03-27',
//     isTopProject: 'true',
//   },
// ]

function Works() {
  const [enableScroll, setScroll] = React.useState(true)

  const [showTopProj, setTopProj] = React.useState(false)
  const [showAllProj, setAllProj] = React.useState(false)
  const [useFilter, setUseFilter] = React.useState(false)

  const [workData, setWorkData] = React.useState([])
  const [workUseData, setUseData] = React.useState(workData)

  const [page, setPage] = React.useState(1)

  const isMoblie = useMediaQuery({
    query: '(max-width:650px)',
  })

  const handlePageChange = page => {
    setPage(page)
  }

  var pageItems = isMoblie ? 3 : 6

  const activeTopProj = () => {
    setUseFilter(false)
    setTopProj(true)
    setAllProj(false)
  }

  const activeAllProj = () => {
    setUseFilter(false)
    setTopProj(false)
    setAllProj(true)
  }

  const fetchWorks = React.useCallback(async () => {
    const res = await api.get('/work')
    console.log(res)
    setWorkData(res.data)
    setTopProj(true)
    setAllProj(false)
  }, [])

  React.useEffect(() => {
    fetchWorks()
    const title = '포트폴리오 - 청담 건축사사무소'
    setMetaTags({
      title: title,
      description:
        '청담 건축사사무소가 설계한 프로젝트를 설명하는 페이지입니다.',
    })

    const titleElement = document.getElementsByTagName('title')[0]
    titleElement.innerHTML = title

    return () => {
      setMetaTags({})
      titleElement.innerHTML = '청담 건축사사무소'
    }
  }, [])

  React.useEffect(() => {
    if (showTopProj) {
      setUseData(workData.filter(work => work.is_top_project === true))
    } else if (showAllProj) {
      setUseData(workData)
    }
  }, [showTopProj, showAllProj, workData])

  React.useEffect(() => {
    if (useFilter) {
      setTopProj(false)
      setAllProj(false)
    }
  }, [useFilter])
  return (
    <ScrollBody>
      <Container>
        <Navbar fixMenuIdx={2} fixSubmenuIdx={0} setScroll={setScroll} />
        <ContentContainer style={enableScroll ? null : { display: 'none' }}>
          <ButtonContainer>
            <ViewButton isActive={showTopProj} onClick={activeTopProj}>
              대표 프로젝트
            </ViewButton>
            <ViewButton isActive={showAllProj} onClick={activeAllProj}>
              전체 보기
            </ViewButton>
            <FilterMenu
              workData={workData}
              setUseData={setUseData}
              isActive={useFilter}
              setActive={setUseFilter}
            />
          </ButtonContainer>
          <Content>
            {workUseData
              .slice(pageItems * (page - 1), pageItems * (page - 1) + pageItems)
              .map((work, index) => (
                <WorkItem
                  key={index}
                  index={work.id}
                  image={work.portrait}
                  title={work.name}
                  desc={work.address}
                />
              ))}
          </Content>

          <PaginationBox>
            <Pagination
              activePage={page}
              itemsCountPerPage={pageItems}
              totalItemsCount={workUseData.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              prevPageText={<KeyboardArrowLeftIcon />}
              nextPageText={<KeyboardArrowRightIcon />}
              firstPageText={<KeyboardDoubleArrowLeftIcon />}
              lastPageText={<KeyboardDoubleArrowRightIcon />}
            ></Pagination>
          </PaginationBox>
        </ContentContainer>
        <FooterBar style={enableScroll ? null : { display: 'none' }} />
      </Container>
    </ScrollBody>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: max(3rem, 40px);
  padding: max(4rem, 40px) 12rem;

  @media (max-width: 1800px) {
    padding: max(4rem, 40px) 6rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  @media (max-width: 650px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  gap: max(1rem, 20px);
`

const Content = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  gap: max(2rem, 30px);

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 10px;
  }

  .pagination * {
    font-size: max(1rem, 18px) !important;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }

  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }

  ul.pagination li a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
  }

  ul.pagination li.active a {
    color: white;
  }

  ul.pagination li.active {
    background-color: var(--primary);
  }

  ul.pagination li:not(.active) a:hover {
    color: var(--primary);
  }

  ul.pagination li a.active {
    color: white;
  }
`

export default Works
