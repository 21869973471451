import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'

import CustomArrow from '../CustomArrow'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Slider from 'react-slick'

function WorkImageSlider({ data }) {
  const settings = {
    nextArrow: (
      <CustomArrow
        name="next"
        icon={
          <IconButton>
            <ArrowForwardIosIcon />
          </IconButton>
        }
      />
    ),
    prevArrow: (
      <CustomArrow
        name="prev"
        icon={
          <IconButton>
            <ArrowBackIosNewIcon />
          </IconButton>
        }
      />
    ),
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <StyledSlider {...settings}>
      {data.map((elm, index) => {
        return <Slide key={index} image={elm.image} />
      })}
    </StyledSlider>
  )
}

WorkImageSlider.propTypes = {
  data: PropTypes.array.isRequired,
}

const Slide = styled.div`
  width: 100%;
  // height: 100%;
  background: var(--light-200);
  background: url(${props => props.image}) var(--light-200);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
`
const StyledSlider = styled(Slider)`
  position: relative;
  height: 75vh;
  @media screen and (max-width: 500px) {
    height: 300px;
  }
  .prev,
  .next {
    position: absolute;
    top: 50%;
    height: auto !important;
    transform: translate(0, -50%);
    z-index: 3;
    display: none;
  }

  .prev svg,
  .next svg {
    font-size: 36px;
    color: white;
  }

  .prev {
    @media screen and (min-width: 500px) {
      left: 80px;
    }

    @media screen and (max-width: 500px) {
      left: 50px;
    }
  }

  .next {
    @media screen and (min-width: 500px) {
      right: 80px;
    }

    @media screen and (max-width: 500px) {
      right: 50px;
    }
  }
  img {
    // width: 100%;
    // object-fit: cover;
  }

  div {
    height: 100%;
  }

  .slick-dots {
    @media screen and (min-width: 500px) {
      bottom: 50px;
      right: 80px;
    }

    @media screen and (max-width: 500px) {
      bottom: 50px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    width: auto;
  }

  .slick-dots li {
    margin: 0 7px;
  }

  .slick-dots li.slick-active button:before {
    opacity: 1;
    background: white;
    color: transparent;
  }

  .slick-dots li button:before {
    width: 18px;
    height: 18px;
    font-size: 0px;
    line-height: 0px;
    opacity: 1;
    color: transparent;
    border-radius: 100%;
    border: 1px solid white;
  }
`
export default WorkImageSlider
