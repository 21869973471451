import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { IconButton, Box, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

function Search({ searchWord, setSearchWord }) {
  const [inputWord, setInputWord] = React.useState(searchWord)

  const handleChange = event => {
    setInputWord(event.target.value)
  }

  const handleSearch = () => {
    setSearchWord(inputWord)
  }

  return (
    <StyledBox sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <TextField
        id="input-with-sx"
        label="제목 검색하기"
        variant="standard"
        value={inputWord}
        onChange={handleChange}
      />
      <IconButton color="primary" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
    </StyledBox>
  )
}
Search.propTypes = {
  searchWord: PropTypes.string.isRequired,
  setSearchWord: PropTypes.func.isRequired,
}

const StyledBox = styled(Box)`
  * {
    font-family: score !important;
    font-size: max(1rem, 18px) !important;
  }

  svg {
    font-size: max(1.5rem, 24px) !important;
  }
  color: black;
`

export default Search
