import React from 'react'
import styled from 'styled-components'

import { Scrollbar } from 'smooth-scrollbar-react'

import Body from '../components/Home/Body/Body'
import Header from '../components/Home/Header/Header'
import setMetaTags from '../utils/setMetaTags'

function Home() {
  const [enableScroll, setScroll] = React.useState(true)

  React.useEffect(() => {
    const title = '메인 - 청담 건축사사무소'
    setMetaTags({
      title: title,
      description: '청담 건축사사무소의 메인 페이지입니다.',
    })

    const titleElement = document.getElementsByTagName('title')[0]
    titleElement.innerHTML = title

    return () => {
      //executed when component is unmounted
      setMetaTags({})
      titleElement.innerHTML = '청담 건축사사무소'
    }
  }, [])

  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: 'glow',
        },
      }}
    >
      <Container>
        <Header setScroll={setScroll} />
        <Body style={enableScroll ? null : { display: 'none' }} />
      </Container>
    </Scrollbar>
  )
}
export default Home

export const Container = styled.div`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: fixed;
  height: 100vh;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`
