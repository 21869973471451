import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { Scrollbar } from 'smooth-scrollbar-react'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

import Pagination from 'react-js-pagination'

import Navbar from '../components/Navbar/Navbar'
import Post from '../components/Board/Post'
import SelectType from '../components/Board/SelectType'
import Search from '../components/Board/Search'
import FooterBar from '../components/FooterBar/FooterBar'
import { BoardData } from '../components/Board/BoardData'
import axios from 'axios'
import setMetaTags from '../utils/setMetaTags'
import { POST_TYPE_KOR_DICT } from '../utils/dictionarys'
import api from '../utils/api'

function Board() {
  const isMoblie = useMediaQuery({
    query: '(max-width:920px)',
  })
  var pageItems = isMoblie ? 5 : 10

  const [enableScroll, setScroll] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [type, setType] = React.useState('all')
  const [searchWord, setSearchWord] = React.useState('')
  const [postData, setPostData] = React.useState([])
  const [postUseData, setPostUseData] = React.useState(postData)

  const handlePageChange = page => {
    setPage(page)
  }

  const fetchPosts = React.useCallback(async () => {
    const res = await api.get('/post')
    setPostData(res.data)
  }, [])

  React.useEffect(() => {
    fetchPosts()
    const title = '공지사항&뉴스 - 청담 건축사사무소'
    setMetaTags({
      title: title,
      description: '청담 건축사사무소의 소식을 전하는 게시판 페이지입니다.',
    })

    const titleElement = document.getElementsByTagName('title')[0]
    titleElement.innerHTML = title

    return () => {
      setMetaTags({})
      titleElement.innerHTML = '청담 건축사사무소'
    }
  }, [])

  React.useEffect(() => {
    if (type !== 'all') {
      setPostUseData(postData.filter(post => post.type === type))
      setPage(1)
    } else {
      setPostUseData(postData)
    }
  }, [type, postData])

  React.useEffect(() => {
    if (searchWord.length > 0) {
      setPostUseData(postData.filter(post => post.title.includes(searchWord)))
      setPage(1)
    } else {
      setPostUseData(postData)
    }
  }, [searchWord, postData])

  return (
    <Scrollbar
      plugins={{
        overscroll: {
          effect: 'glow',
        },
      }}
    >
      <Scroll>
        <Container>
          <Navbar fixMenuIdx={1} fixSubmenuIdx={2} setScroll={setScroll} />
          <ContentContainer style={enableScroll ? null : { display: 'none' }}>
            <Components>
              <SelectType
                type={type}
                setType={setType}
                setPostUseData={setPostUseData}
              />
              <Search searchWord={searchWord} setSearchWord={setSearchWord} />
            </Components>
            <Content>
              <Post
                no="No"
                type="분류"
                title="제목"
                created="게시일"
                highlighted={true}
              />
              {postUseData
                .slice(
                  pageItems * (page - 1),
                  pageItems * (page - 1) + pageItems
                )
                .map((notice, index) => {
                  if (type === 'all' || notice.type === type) {
                    return (
                      <Post
                        key={index}
                        index={notice.id}
                        no={(
                          postUseData.length -
                          (page - 1) * pageItems -
                          index
                        ).toString()}
                        type={POST_TYPE_KOR_DICT[notice.type]}
                        title={notice.title}
                        created={notice.created_date}
                      />
                    )
                  }
                })}
            </Content>
            <PaginationBox>
              <Pagination
                activePage={page}
                itemsCountPerPage={pageItems}
                totalItemsCount={postUseData.length}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText={<KeyboardArrowLeftIcon />}
                nextPageText={<KeyboardArrowRightIcon />}
                firstPageText={<KeyboardDoubleArrowLeftIcon />}
                lastPageText={<KeyboardDoubleArrowRightIcon />}
              ></Pagination>
            </PaginationBox>
          </ContentContainer>
          <FooterBar style={enableScroll ? null : { display: 'none' }} />
        </Container>
      </Scroll>
    </Scrollbar>
  )
}

const Scroll = styled.div`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: fixed;
  height: 100vh;
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: max(3rem, 40px);
  padding: max(4rem, 40px) 12rem;

  @media (max-width: 920px) {
    padding: max(4rem, 40px) 6rem;
  }
`

const Content = styled.div`
  display: grid;

  border-top: 3px solid var(--primary);
`

const Components = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

const PaginationBox = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 10px;
  }

  .pagination * {
    font-size: max(1rem, 18px) !important;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }

  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }

  ul.pagination li a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
  }

  ul.pagination li.active a {
    color: white;
  }

  ul.pagination li.active {
    background-color: var(--primary);
  }

  ul.pagination li:not(.active) a:hover {
    color: var(--primary);
  }

  ul.pagination li a.active {
    color: white;
  }
`

export default Board
