import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import MenuIcon from '@mui/icons-material/Menu'

import Logo from './NavItem/Logo'
import MenuButton from './NavItem/MenuButton'
import MenuItem from './MenuItem/MenuItem'
import MenuSubItem from './MenuItem/MenuSubItem'
import MenuOverview from './MenuOverview/MenuOverview'

function Navbar({ fixMenuIdx = -1, fixSubmenuIdx = -1, setScroll }) {
  const [isNavHover, setNavHover] = React.useState(false)
  const [menuIdx, setMenuIdx] = React.useState(-1)
  const [isOverviewOpen, setOverviewOpen] = React.useState(false)

  const showOverview = () => {
    setOverviewOpen(true)
    setScroll(false)
  }

  const hideOverview = () => {
    setOverviewOpen(false)
    setScroll(true)
  }

  React.useEffect(() => {
    if (fixMenuIdx >= 0) {
      setNavHover(true)
      setMenuIdx(fixMenuIdx)
    }
  }, [])

  const menuInfo = [
    { title: 'HOME', submenu: [['홈페이지', `/`]] },
    {
      title: 'ABOUT',
      submenu: [
        ['회사개요', `/about/overview`],
        ['CEO 인사말', `/about/greeting`],
        ['공지사항 & 뉴스', `/about/board`],
      ],
    },
    {
      title: 'WORKS',
      submenu: [['포트폴리오', `/works`]],
    },
    {
      title: 'CONTACT',
      submenu: [
        ['오시는 길', `/contact/directions`],
        ['문의하기', `/contact/inquiry`],
      ],
    },
  ]

  return (
    <Container
      className={isNavHover ? 'fade-in-3' : null}
      onMouseLeave={() => {
        if (fixMenuIdx === -1) {
          setNavHover(false)
          setMenuIdx(-1)
        } else {
          setMenuIdx(fixMenuIdx)
        }
      }}
    >
      {isOverviewOpen ? (
        <MenuOverview hideOverview={hideOverview} menuInfo={menuInfo} />
      ) : null}
      <NavContainer
        style={
          isNavHover
            ? { background: 'white' }
            : { borderBottom: '1px solid rgba(255, 255, 255, 0.2)' }
        }
      >
        <Logo isNavHover={isNavHover} />

        <MenuContainer
          onMouseEnter={() => {
            if (fixMenuIdx === -1) setNavHover(true)
          }}
        >
          <MenuList>
            {menuInfo.map((menu, index) => (
              <MenuItem
                key={index}
                isNavHover={isNavHover}
                isItemHover={index === menuIdx ? true : false}
                onMouseEnter={() => setMenuIdx(index)}
              >
                {menu.title}
              </MenuItem>
            ))}
          </MenuList>
          <MenuButton isNavHover={isNavHover} onClick={showOverview}>
            <MenuIcon />
          </MenuButton>
        </MenuContainer>
      </NavContainer>

      {isNavHover && menuIdx >= 0 ? (
        menuInfo[menuIdx].submenu.length > 0 ? (
          <SubMenuContainer
            className="fade-in-3"
            style={
              fixMenuIdx >= 0
                ? { borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }
                : null
            }
          >
            {menuInfo[menuIdx].submenu.map(([title, link], index) => {
              var isFocused = false
              if (menuIdx === fixMenuIdx && index === fixSubmenuIdx)
                isFocused = true

              return (
                <MenuSubItem key={index} target={link} isFocused={isFocused}>
                  {title}
                </MenuSubItem>
              )
            })}
          </SubMenuContainer>
        ) : null
      ) : null}
    </Container>
  )
}

Navbar.propTypes = {
  fixMenuIdx: PropTypes.number,
  fixSubmenuIdx: PropTypes.number,
  setScroll: PropTypes.func,
}

const Container = styled.div``

const NavContainer = styled.nav`
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 100vw;
  height: max(min(5rem, 120px), 100px);
  padding: 0 60px;
  box-sizing: border-box;
  z-index: 3;
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;

  margin: 0;
  padding: 0;
  list-style-type: none;
`

const MenuList = styled.div`
  display: flex;
  @media (max-width: 750px) {
    display: none;
  }
`

const SubMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;

  width: 100%;
  height: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 3;

  @media (max-width: 450px) {
    flex-direction: column;
    height: 120px;
    gap: 20px;
  }
`

export default Navbar
