import styled from 'styled-components'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio'
import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'

function FilterGroups({ label, value, setValue, filters, columnNum = 1 }) {
  const handleChange = event => {
    setValue(event.target.value)
  }
  return (
    <Container>
      <Title>{label}</Title>
      <Content style={{ gridTemplateColumns: `repeat(${columnNum}, 1fr)` }}>
        {filters.map((filter, index) => (
          <StyledFormControlLabel
            key={index}
            checked={value === filter.value}
            value={filter.value}
            control={<StyledRadio />}
            label={filter.label}
            onChange={handleChange}
          />
        ))}
      </Content>
    </Container>
  )
}

FilterGroups.propTypes = {
  label: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  columnNum: PropTypes.number,
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Content = styled.div`
  display: grid;
  gap: 10px;
`

const Title = styled.div`
  text-align: center;
  font-family: score;
  font-weight: 500;
  font-size: max(1.1rem, 18px);

  padding-bottom: max(1rem, 16px);
  border-bottom: 2px solid var(--light-600);
`

const StyledRadio = styled(Radio)`
  .MuiSvgIcon-root {
    font-size: max(1.2rem, 18px) !important;
    color: var(--light-600);
  }

  &.Mui-checked .MuiSvgIcon-root {
    color: var(--primary) !important;
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  * {
    font-family: score !important;
    font-size: max(0.9rem, 16px) !important;
  }
`

export default FilterGroups
