import styled from 'styled-components'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Navbar from '../components/Navbar/Navbar'
import FooterBar from '../components/FooterBar/FooterBar'
import ScrollBody from '../components/ScrollBody'
import WorkImageSlider from '../components/Works/WorkImageSlider'
import StyledLink from '../components/StyledLink'
import { REGION_KOR_DICT } from '../utils/dictionarys'
import api from '../utils/api'

const WORK_INIT_STATE = {
  name: '',
  addr: '',
  portrait: ``,
  images: [],
  region: '',
  type: '',
  design_date: '',
  construction_date: '',
  site_area: '',
  total_floor_area: '',
  building_area: '',
  number_of_stories: '',
  description:
    '포트폴리오에 들어갈 건물의 간단한 소개입니다. 이 영역은 건축물에 대한 소개 뿐만이 아니라, 설계철학이나 컨셉을 적어 주셔도 됩니다. 내용이 너무 많으면 역으로 이상하게 보일 수 있으니 적당히 4줄정도 적어주세요.',
}

function InfoLabel({ children }) {
  return <LabelContainer>{children}</LabelContainer>
}

const LabelContainer = styled.div`
  font-family: score;
  font-weight: 600;
  font-size: max(1.6rem, 24px);

  padding: max(0.9rem, 8px);
  border-bottom: 3px solid var(--primary);

  color: var(--primary);
`

function InfoIconWidget({ image, label, value }) {
  return (
    <IconWidgetContainer>
      <img src={image} alt={image} />
      <span className="label">{label}</span>
      <span className="value">{value}</span>
    </IconWidgetContainer>
  )
}

const IconWidgetContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: max(1rem, 16px);

  font-family: score;

  .label {
    font-weight: 500;
    font-size: max(1rem, 14px);
  }

  .value {
    font-size: max(1.2rem, 16px);
  }

  img {
    width: max(4rem, 42px);
    object-fit: cover;
  }
`

function InfoWidget({ label, value }) {
  return (
    <InfoWidgetContainer>
      <div className="label">{label}</div>
      <div>{value}</div>
    </InfoWidgetContainer>
  )
}

const InfoWidgetContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: score;
  font-size: max(1.1rem, 16px);
  border-bottom: 2px solid var(--light-600);
  padding: 0 max(2.2rem, 28px);
  padding-bottom: max(1.2rem, 18px);
  gap: max(1rem, 16px);

  .label {
    font-weight: 500;
  }

  div {
    text-align: right;
    white-space: pre-line;
    word-break: keep-all;
    line-height: max(1.5rem, 24px);
  }
`

function toArea(value) {
  return `${parseInt(value).toLocaleString()}py`
}

function WorkDetail() {
  const [enableScroll, setScroll] = React.useState(true)
  const [data, setData] = React.useState(WORK_INIT_STATE)
  const { id } = useParams()
  const navigate = useNavigate()

  const fetchWork = async () => {
    try {
      const res = await api.get(`/work/${id}`)
      setData(res.data)
      console.log(res.data)
    } catch (err) {
      switch (err.response.status) {
        case 404:
          alert('해당 작업이 없습니다.')
          navigate('/works/')
          break
        default:
          alert('알 수 없는 에러가 발생했습니다. 잠시 후 다시 시도해주세요.')
          navigate('/works')
      }
    }
  }

  React.useEffect(() => {
    fetchWork()
  }, [])

  return (
    <ScrollBody>
      <Container>
        <Navbar fixMenuIdx={2} fixSubmenuIdx={0} setScroll={setScroll} />
        <ContentContainer style={enableScroll ? null : { display: 'none' }}>
          <Title>
            <div className="primary">{data.name}</div>
            {data.addr}
          </Title>

          <WorkImageSlider
            data={[].concat([{ image: data.portrait }], data.images)}
          />

          <InfoFlexContainer>
            <Info>
              <InfoLabel>OVERVIEW</InfoLabel>
              <InfoContent
                gap="max(3rem, 40px)"
                padding="max(2.5rem, 40px) max(2.5rem, 20px)"
              >
                <InfoIconContainer>
                  <InfoIconWidget
                    image={`images/works/schedule.png`}
                    label="설계년도"
                    value={`${data.design_date.substring(0, 4)}년`}
                  />
                  <InfoIconWidget
                    image={`images/works/map-location.png`}
                    label="시공지역"
                    value={`${REGION_KOR_DICT[data.region]}`}
                  />
                  <InfoIconWidget
                    image={`images/works/area.png`}
                    label="연면적"
                    value={toArea(data.total_floor_area)}
                  />
                  <InfoIconWidget
                    image={`images/works/floor.png`}
                    label="규모"
                    value={data.number_of_stories}
                  />
                </InfoIconContainer>
                <div className="description">{data.description}</div>
              </InfoContent>
            </Info>
            <Info>
              <InfoLabel>DETAILED INFO.</InfoLabel>
              <InfoContent
                gap={'max(1.6rem, 24px)'}
                padding="max(2.5rem, 40px) 0"
              >
                <InfoWidget
                  label="PROJECT"
                  value={`${data.design_date.substring(0, 4)}년\n${data.name}`}
                />
                <InfoWidget label="대지 위치" value={`${data.address}`} />
                <InfoWidget label="건물 용도" value={`${data.use}`} />
                <InfoWidget
                  label="연면적"
                  value={`${toArea(data.total_floor_area)}`}
                />
                <InfoWidget label="규모" value={`${data.number_of_stories}`} />
              </InfoContent>
            </Info>
          </InfoFlexContainer>
          <StyledLink to="/works">
            <ExitButton>목&nbsp;&nbsp;&nbsp;&nbsp;록</ExitButton>
          </StyledLink>
        </ContentContainer>
        <FooterBar style={enableScroll ? null : { display: 'none' }} />
      </Container>
    </ScrollBody>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: max(3rem, 20px);
  padding: max(4rem, 40px) 12rem;

  @media (max-width: 1800px) {
    padding: max(4rem, 40px) 6rem;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: max(0.8rem, 14px);

  font-family: score;
  font-weight: 300;
  color: black;

  .primary {
    font-weight: 500;
    font-size: max(2rem, 24px);
    color: var(--primary);
  }
`

const InfoFlexContainer = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: max(8rem, 30px);

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
  }

  .description {
    font-family: score;
    font-size: max(1.2rem, 16px);
    line-height: max(2.4rem, 32px);
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap};
  padding: ${props => props.padding};
`

const InfoIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ExitButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: max(15rem, 180px);
  height: max(4rem, 60px);

  background: var(--primary);
  color: white;

  font-family: score;
  font-size: max(1.3rem, 21px);

  margin: 0 auto;
`
export default WorkDetail
