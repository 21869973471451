export const REGION_KOR_DICT = {
  SEOUL: '서울특별시',
  INCHEON: '인천광역시',
  DAEJEON: '대전광역시',
  DAEGU: '대구광역시',
  GWANGJU: '광주광역시',
  ULSAN: '울산광역시',
  BUSAN: '부산광역시',
  'GYEONGGI-DO': '경기도',
  'GANGWON-DO': '강원도',
  'CHUNGCHEONG-DO': '충청도',
  'GYEONGSANG-DO': '경상도',
  'JEOLLA-DO': '전라도',
  'JEJU-DO': '제주도',
  others: '기타',
}

export const POST_TYPE_KOR_DICT = {
  ALL: '전체',
  GUIDE: '안내',
  BID: '입찰',
  AWARD: '수상',
  RECRUITMENT: '채용',
  NEWS: '뉴스',
}
