import React from 'react'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import useDidMountEffect from '../../../../useDidMountEffect'
import styled from 'styled-components'

function FlipArrow({ isArrowFlip }) {
  const [animation, setAnimation] = React.useState('')

  useDidMountEffect(() => {
    isArrowFlip ? setAnimation('rotate-flip') : setAnimation('rotate-flip-back')
  }, [isArrowFlip])

  return <StyledArrowDropDownIcon className={animation} />
}

FlipArrow.propTypes = {
  isArrowFlip: PropTypes.bool.isRequired,
}

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  font-size: max(2rem, 32px) !important;
`

export default FlipArrow
