import styled from 'styled-components'

import { useInView } from 'react-intersection-observer'

function SectionOne({ style }) {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  })
  return (
    <Container ref={ref} style={style}>
      <ImageContainer className={inView ? 'expand-both' : null} />
      <TextContainer>
        <Title
          className={inView ? 'fade-in-bottom' : null}
          style={inView ? { animationDelay: '1.5s' } : { opacity: '0' }}
        >
          ABOUT
          <br />
          <span style={{ color: 'white' }}>CHEONGDAM</span>
        </Title>
        <Description
          className="fade-in-bottom"
          style={{ animationDelay: '2s' }}
        >
          청담 건축사사무소는 고객님의 말씀을
          <br /> 「속되지 않은 청아한 이야기」인 청담같이 생각합니다.
        </Description>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100vw;

  @media (min-height: 500px) {
    flex: 1;
  }
  @media (max-height: 500px) {
    height: 400px;
  }
`

const TextContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 5%;
  transform: translate(0, -30%);

  font-family: score;

  color: #134187;

  @media (max-width: 450px) {
    transform: translate(0, -22%);
  }
`

const Title = styled.div`
font-weight 600;
font-size: max(6rem, 72px);

@media(max-width: 450px) {
    font-size: 52px;
}
`

const Description = styled.div`
  font-weight: 500;
  font-size: max(1.4rem, 20px);
  line-height: max(2rem, 32px);
  color: white;

  margin-top: 10%;
`

const ImageContainer = styled.div`
  width: 0%;
  height: 70%;
  margin: 0 auto;
  background-image: url('images/overview_one.jpg');
  background-size: cover;
  background-position: center bottom;
`

export default SectionOne
