import styled from 'styled-components'

function ResponsiveText({ children }) {
  return <Container>{children}</Container>
}
export const Container = styled.span`
  @media (max-width: 750px) {
    display: inline !important;
    white-space: normal !important;
  }

  display: block;
  white-space: nowrap;

  word-break: keep-all;
`
export default ResponsiveText
