import PropTypes from 'prop-types'
import styled from 'styled-components'

function SectionInfo({ title, children, className }) {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      <Indicator />
      <Description>{children}</Description>
    </Container>
  )
}

SectionInfo.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default SectionInfo

const Container = styled.div`
  width: auto;
`

const Title = styled.div`
  font-family: hanMaum;
  font-size: max(2.4rem, 36px);
  line-height: max(2.9rem, 48px);
  @media screen and (max-width: 450px) {
    font-size: 36px;
    line-height: 48px;
  }
`

const Indicator = styled.div`
  width: max(3rem, 36px);
  height: 3px;
  margin: 10px 5px;
  background: black;
`

const Description = styled.div`
  font-family: score;
  font-weight: 300;
  font-size: max(1rem, 14px);
  line-height: max(1.6rem, 24px);

  @media screen and (max-width: 450px) {
    font-size: 13px;
    line-height: 21px;
  }
`
